
import React from 'react';
import { DsNodeModel } from './ds_nodemodel';
import { DiagramEngine } from '@projectstorm/react-diagrams';
import {  AnalyticsTypes, getPortPositionGenerator, pathConnectingPorts, previewStatusInfo } from '../enums';
import _, { isEmpty, debounce, has } from 'lodash';
import { ShowWhenTrue } from '../../../helpers';

import { Dropdown, DropdownOptions } from '../../form/dropdown';
import Form, { SelectField } from '../../form';
import { _selectoptionType } from '../../form/select-field';
import '../styles.scss';

import classNames from 'classnames';
import { store as _store } from '../../../store';
import { showProperties,  toggleModal, setComponentIdForRunTillShow, setCopiedComponentInfo, componentToBeCopiedInfo, refreshComponentFormData, executeActiveWorfklowStreamingPreview, addCustomComponentToList } from '../../../store/workflow';
import { PREVIEW_INITIALIZED, PREVIEW_SUCCESS,  PREVIEW_FAILED, PreviewComponentStatusTypes, WorkflowHandler,
    GetComponentOutputResponse,
    ComponentOutputTypes,
    OutputDataFormat} from '../../../api/workflow-handler';
import { AnalyticsGraphs } from './graphs';
import { WorkflowCanvas } from '..';
import { Table } from './table';
import { DsPortModel } from '../port/ds_portmodel';
import { PortWidget } from '../port/port-widget';
import Editor from '@monaco-editor/react';
import { TableForModelSummary } from './table-model-summary';
import { GraphsForModelSummary } from './graphs-model-summary';
import { InvalidPropertiesIcon, PortManagementIcon, PlusIconForAnalytics, UnAvailableIcon, StreamingWorkflowPlayIcon, PreviewDisabledIcon } from '../assets/icons';
import { Tooltip } from 'antd';
import { runAnalyticsOnActiveWorkflow } from '../../../store/analytics';
import { goToAnalyticsPage } from '../../../store/common';
import { iconList } from '../../../pages/workflow-page/enums';
import { CustomComponent, JobPreviewIcon } from '../../../pages/workflow-page/assets/icons';
import { InPageSpinner } from '../../spinners/in-page-spinner';
import { studioTabs } from '../../header-sidebar/enums';
import { WorkflowCanvasTabInfo } from '../../../store/canvas';
import { StompSubscription, IMessage } from '@stomp/stompjs';
import { SocketHandler } from '../../../api/socket';
import { createNodeTestId, handleDeleteNode } from './utils';
import { ExecutionEnvModes } from '../../../constants/enums';
import workflowActionHandler from '@services/WorkflowActionHandler';
import { TooltipTop } from '@components/tooltips';

export interface DsNodeWidgetProps {
	node: DsNodeModel;
	engine: DiagramEngine;
    size?: number;
}

const analyticsOptions = [
    {label: AnalyticsTypes.table, value: AnalyticsTypes.table},
    {label: AnalyticsTypes.customizePlot, value: AnalyticsTypes.customizePlot}
];

type totalPortsNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7;
export type analyticsDimensions = { height: number; width: number }


const graphTypesOptions =[{label: 'Pie', value: 'pie'}, {label: 'Bar', value: 'bar'}];
type HoverStateOptions = {title: string; nodeId: string; className?: string; Icon?: React.FC<{nodeId: string}>; subtitle?: string; };

export default class DsNodeComponent extends React.Component<DsNodeWidgetProps,
    {   showAnalytics: boolean;
        activeAnalyticsOption: string;
        previewComponentStatus: PreviewComponentStatusTypes | null;
        changeSuccessColorToGreen: boolean;
        hasComponentPreviewData: boolean;
        // {output1: OutputDataFormat, summary1: OutputDataFormat, output2: OutputDataFormat, summary2:....}
        componentOutputData: ComponentOutputTypes;
        // only output's model summary is shown
        // {output1: true, output2: true ....}
        outputTypesWithModelSummary: Record<string, true>;
        showInputPorts: boolean;
        analyticsDimensions: analyticsDimensions;
        activeOutputType: string;
        showEditor: boolean;
        modelSummarygraphType: 'pie' | 'bar';
        showRenameOptions: boolean;
        renameFieldValue: string;
        idOfComponentForRunTillHere: string;
        isErrorComponentInJobRun: boolean;
        showJobLogsInWorkflowPage: boolean;
        hasActivePlotInAnalyticsPage: boolean;
        showPlotInProgressSpinner: boolean;
        numberOfActivePlots: number;
        previewStatusInfo: WorkflowCanvasTabInfo['previewStatusInfo'];
        activeExecutionEnv: ExecutionEnvModes;
        isPreviewSessionCreationInProgress: boolean;
        isOptionsDropdownOpen: boolean;
    }>
{
    nodeRef: React.RefObject<HTMLDivElement>;
    subtitleDivRef: React.RefObject<HTMLDivElement>;
    unsubscribe: any;
    inAnalyticsMode: boolean;
    socketSubscriptionForStreaming: null | StompSubscription;
    responseBody!: string;
    isPreviewDisabled: boolean;
    constructor(props: Readonly<DsNodeWidgetProps>) {
        super(props);
        this.state= {
            showAnalytics: false,
            activeAnalyticsOption: analyticsOptions[0].value,
            previewComponentStatus: null,
            changeSuccessColorToGreen: false,
            componentOutputData: {
                output1: {
                    data: [],
                    schema: {fields: []}
                },
                summary1: {
                    data: [],
                    schema: {fields: []}
                }
            },
            outputTypesWithModelSummary: {},
            hasComponentPreviewData: false,
            showInputPorts: true,
            activeOutputType: 'output1',
            analyticsDimensions: { height: 300, width: 500 },
            showEditor: false,
            modelSummarygraphType: 'pie',
            showRenameOptions: false,
            renameFieldValue: '',
            idOfComponentForRunTillHere: '',
            isErrorComponentInJobRun: false,
            showJobLogsInWorkflowPage: false,
            hasActivePlotInAnalyticsPage: false,
            showPlotInProgressSpinner: false,
            numberOfActivePlots: 0,
            previewStatusInfo: undefined,
            activeExecutionEnv: ExecutionEnvModes.Spark,
            isPreviewSessionCreationInProgress: false,
            isOptionsDropdownOpen: false,
        };
        this.nodeRef = React.createRef<HTMLDivElement>();
        this.subtitleDivRef = React.createRef<HTMLDivElement>();
        this.inAnalyticsMode = !!(window.location.pathname.includes(studioTabs.tabs.visualizations.url));
        this.socketSubscriptionForStreaming = null;
        this.isPreviewDisabled = this.props.node.extras.previewAllowed === false;
    }

    resetComponentOutputData = () => {
        const componentOutputData = {
            output1: {
                data: [],
                schema: {fields: []}
            },
            summary1: {
                data: [],
                schema: {fields: []}
            }
        };
        this.setState({ componentOutputData, hasComponentPreviewData: false });
    }

    parseComponentPreviewOutput = (output: GetComponentOutputResponse['output']) => {
        if(output) {
            const componentOutput: ComponentOutputTypes = JSON.parse(output as unknown as string);
            // for model summary, data in summary is not needed. Hence, summary data with model summary is removed.
            // only data in output is shown for modelsummary
            // const componentOutput: ComponentOutputTypes = modelSummaryOutput;

            const keysToBeRemoved: string[] = [];
            const outputTypesWithModelSummary: Record<string, true> = {};
            Object.entries(componentOutput).forEach(([type, __outputInfo]: [string, OutputDataFormat] ) => {
                const isModelSummaryComponent = __outputInfo?.schema?.fields.find(field => field.name === 'model_summary');
                if(isModelSummaryComponent) {
                    if(type.includes('summary')){
                        if(isModelSummaryComponent) keysToBeRemoved.push(type);
                    } else {
                        outputTypesWithModelSummary[type] = true;
                    }
                }

            });
            keysToBeRemoved.forEach(key => delete componentOutput[key as keyof typeof componentOutput]);
            this.setState({ componentOutputData: componentOutput, outputTypesWithModelSummary  });
        }
    }

    handleStreamingDataUpdate = (response: IMessage) => {
        if(!!(response.body) && this.responseBody !== response.body) {
            this.responseBody = response.body;
            const componentOutput = JSON.parse(response.body) as GetComponentOutputResponse;
            this.parseComponentPreviewOutput(componentOutput.output);
        }
    }

    connectToSocketIfRequired = async() => {
        const { previewStatusInfo } = this.state;
        if(!this.socketSubscriptionForStreaming && previewStatusInfo) {
            const isSocketConnected = await SocketHandler.Connect();
            if(isSocketConnected) {
                this.socketSubscriptionForStreaming = SocketHandler.SubscribeForComponentData({
                    type: 'PREVIEW',
                    sessionId: previewStatusInfo.workflowSessionId,
                    componentId: this.props.node.getID(),
                    callback: this.handleStreamingDataUpdate
                });
            }
        }
    }

    handleComponentAnalyticsResponse = (response: GetComponentOutputResponse) => {
        if(!!(response.output) && !this.state.hasComponentPreviewData){
            this.parseComponentPreviewOutput(response.output);
        }
        this.setState({  hasComponentPreviewData: true });
    }


    handleUpdateComponentStatus = debounce((previewStatusInfo: WorkflowCanvasTabInfo['previewStatusInfo']) => {
        if(previewStatusInfo) {
            const nodeId = this.props.node.getID();
            // const previewComponentStatus = previewStatusInfo.components;
            const previewComponentStatus = previewStatusInfo.components[nodeId]?.status as PreviewComponentStatusTypes;
            if(!(previewStatusInfo.isStreaming && previewStatusInfo.isRunning)) {
                if(previewComponentStatus === PREVIEW_SUCCESS && !this.state.hasComponentPreviewData) {
                    // // @ts-ignore
                    // this.worker = new Worker();
                    // // @ts-ignore
                    // this.worker.postMessage(componentInfo);
                    // // @ts-ignore
                    // this.worker.addEventListener('message', e => {
                    //     // console.log(e.data);
                    //     // this.handleComponentAnalyticsResponse(e.data)
                    // });
                    WorkflowHandler.GetComponentOutput(previewStatusInfo.workflowSessionId, nodeId, this.handleComponentAnalyticsResponse);
                }
                else if(previewComponentStatus === PREVIEW_INITIALIZED && this.state.hasComponentPreviewData) {
                    this.resetComponentOutputData();
                }
            }
            if(previewStatusInfo !== this.state.previewStatusInfo) this.setState({ previewComponentStatus, previewStatusInfo });
        } else if(this.state.previewStatusInfo) {
            this.resetComponentOutputData();
            this.setState({ previewComponentStatus: null, previewStatusInfo: undefined });
        }
    }, 500)

    getReduxState = () => {
        const { WorkflowReducer, CanvasReducer, CommonReducer } = _store.getState();
        const { node } = this.props;

        if(this.inAnalyticsMode) {
            const { activeTab, openTabs } = CanvasReducer.analytics;
            const activeTabInfo = openTabs.get(activeTab.id);
            if(activeTabInfo) {
                const _state: any  = {};
                const currentNodeId = node.getID();

                const isCurrentIdSelected = activeTabInfo.info.componentIdsOfPlots.find(_id => _id ===currentNodeId);
                if(!this.state.hasActivePlotInAnalyticsPage && isCurrentIdSelected) {
                    _state.hasActivePlotInAnalyticsPage = true;
                    // let numberOfActivePlots = 0;


                } else if(this.state.hasActivePlotInAnalyticsPage && !isCurrentIdSelected) {
                    _state.hasActivePlotInAnalyticsPage = false;
                }
                if(activeTabInfo.info.activeComponentIdForAnalytics === currentNodeId && activeTabInfo.info.showPlotInProgressSpinner && !this.state.showPlotInProgressSpinner) {
                    _state.showPlotInProgressSpinner = true;
                } else if (!activeTabInfo.info.showPlotInProgressSpinner && this.state.showPlotInProgressSpinner) {
                    _state.showPlotInProgressSpinner = false;
                }

                if(_state.hasActivePlotInAnalyticsPage || this.state.hasActivePlotInAnalyticsPage){
                    const numberOfActivePlots = Object.values(activeTabInfo.info.graphsInfo.data).reduce((total, graphInfo) => {
                        if(graphInfo.componentId === currentNodeId) return total += 1;
                        return total;
                    }, 0);
                    _state.numberOfActivePlots = numberOfActivePlots;
                }
                this.setState({ ..._state });
                // if(activeTabInfo.info.activeComponentIdForAnalytics === node.getID() && activeTabInfo.info.showPlotInProgressSpinner) {
                //     _state.hasActivePlotInAnalyticsPage = true;
                // }
            }
        } else {
            const { activeTab, openTabs } = CanvasReducer.workflowEditor;
            // const previewComponentStatus = openTabs.get(activeTab.id)?.info.previewStatusInfo?.components;
            const activeTabInfo = openTabs.get(activeTab.id)?.info;
            let _state: any = {
                activeExecutionEnv: CommonReducer.activeExecutionEnv
            }

            if(activeTabInfo) {
                const { previewStatusInfo, consoleInfo, isPreviewSessionCreationInProgress } =  activeTabInfo;
                const { idOfComponentForRunTillHere } = WorkflowReducer;
                
                _state = {
                    ..._state,
                    isErrorComponentInJobRun: node.getID() === consoleInfo?.jobInfo?.errorComponentId,
                    showJobLogsInWorkflowPage: !isEmpty(consoleInfo?.logs),
                    idOfComponentForRunTillHere: idOfComponentForRunTillHere,
                    isPreviewSessionCreationInProgress: isPreviewSessionCreationInProgress
                }

                this.handleUpdateComponentStatus(previewStatusInfo);

                this.setState({ ..._state });
            }
        }


    }

    subscribeToStore = () => {
        this.unsubscribe = _store.subscribe(() => this.getReduxState());
    }


    removeEventListenerForRename = () =>{
        document.removeEventListener('mousedown', this.handleClickOutsideRenameField);
    }


    saveRenameState = () => {
        if(this.state.renameFieldValue.trim() !== '') {
            this.props.node.getOptions().title = this.state.renameFieldValue;
            this.removeEventListenerForRename();
            this.setState({ showRenameOptions: false });
        }
    }

    handleClickOutsideRenameField = (event: MouseEvent) => {
        if(this.nodeRef.current && !this.nodeRef.current.contains(event.target as any)) {
            if(this.state.renameFieldValue.trim() !== '') {
                this.saveRenameState();
            } else {
                // if field value is empty - clicking outside should restore it to the old value
                this.restoreToOriginalValue();
            }
        }
    }

    showRenameTitle = () => {
        this.setState({ showRenameOptions: !this.state.showRenameOptions, renameFieldValue: this.props.node.getOptions().title });
        document.addEventListener('mousedown', this.handleClickOutsideRenameField);
    }

    restoreToOriginalValue = () => {
        this.removeEventListenerForRename();
        this.setState({ showRenameOptions: false });
    }

    handleRenameFieldKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if(e.keyCode === 27) {
            this.restoreToOriginalValue();
        } else if(e.keyCode === 13) this.saveRenameState();
    }


    generatePort = (port: DsPortModel, portNumber: number, totalPorts: totalPortsNumber, outputPorts = false) => {
        const { previewStatusInfo } = this.state;

        // Ports are numbered from 0, 1, 2, 3 ..
        // -1 means there's only one port
        const multiplePorts = portNumber !== -1;

        const portCords = getPortPositionGenerator[totalPorts as 2 | 3] && getPortPositionGenerator[totalPorts as 2 | 3](portNumber);

        // With ports greater than 4, node's height changes to 80px
        const yCenterOfLinks = (this.props.node.portsIn.length > 4 || this.props.node.portsOut.length > 4) ? 58: 32;
        const { isVariablePort } = port?.getOptions()||{};

        const style= multiplePorts || isVariablePort ? {
            transform: `${multiplePorts ? `translate(${outputPorts ? -(portCords.x) : (portCords.x)}px, ${portCords.y}px) scale(var(--portscale))`: ''} ${isVariablePort ? 'rotate(45deg) scale(var(--portscale))': ''}` } : {transform: 'scale(var(--portscale))'};
        return(
            <>
                <PortWidget
                    className={classNames('ds__node__port',
                        {'multiplePorts': multiplePorts ,
                        'ds__node__variablePort':  isVariablePort ,
                        'port__connected': port?.isPortConnected(),
                        'port__selected': port?.isSelected(),
                            'portDisabled__preview': this.isPreviewDisabled && previewStatusInfo?.components
                            // show port as disabled until preview state is reset
                        })
                    }
                    port={port}
                    engine={this.props.engine}
                    key={port?.getID()}
                    // output ports have negative x w.r.t input ports
                    style={style}
                >
                    <div className="port__innerDot"  />
                </PortWidget>
                <ShowWhenTrue show={multiplePorts}>
                    <svg className="svg__externalPortsBox" onMouseOver={(e) => e.stopPropagation()}>
                        {/* -1 is for horizontal links to be straight */}
                        <path d={multiplePorts ? `M80 ${yCenterOfLinks} l ${portCords.x} ${portCords.y-1}`: ''} strokeWidth="1.5" stroke="#2A2C42" fill="none" />
                    </svg>
                </ShowWhenTrue>

            </>
        );
    }


    showGraphOptions = () => {
        this.setState({ showAnalytics: true });
    }

    handleShowProperties = () => {
        _store.dispatch(showProperties() as any);
    }

    handleDisconnectSocket = () => {
        const { previewStatusInfo } = this.state;
        if(previewStatusInfo && this.socketSubscriptionForStreaming) {
            SocketHandler.UnSubscribeForComponentData({
                type: 'PREVIEW',
                componentSubscription: this.socketSubscriptionForStreaming,
                sessionId: previewStatusInfo.workflowSessionId,
                componentId: this.props.node.getID()
            });
            this.socketSubscriptionForStreaming = null;
        }
    }

    handleToggleAnalytics = (action: boolean) => {
        // if(action && this.state.previewStatusInfo?.isStreaming && this.state.previewStatusInfo.isRunning) {
        if(action && this.state.previewStatusInfo?.isStreaming) {
            this.connectToSocketIfRequired();
        }
        if(!action) {
            this.handleDisconnectSocket();
        }
        this.props.node.setLocked(action);
        this.setState({ showAnalytics: action });
        setTimeout(() => WorkflowCanvas.repaintCanvas(), 1);
    }

    componentDidMount(){
        if(!this.props.node.getOptions().isMounted) {
            this.props.node.getOptions().isMounted = true;
        }
        this.subscribeToStore();
        // for initial mount
        this.getReduxState();
        if(this.props.node.extras.key === 'decisionTree_2_demo') this.setState({ modelSummarygraphType: 'model' as any });
    }

    componentWillUnmount() {
        this.unsubscribe();
        this.handleDisconnectSocket();
        this.removeEventListenerForRename();

    }

    refreshPortsPosition = () => {
        // this is done to force refresh the ports generation as ports coordinates are getting updated
        // when a port is added
        WorkflowCanvas.repaintCanvas();
    }

    handleDeleteNode = () => {
        handleDeleteNode(this.props.node, _store.dispatch);
    }

    handleDimensionChange = (type: keyof analyticsDimensions, value: string) => {
        const  { analyticsDimensions } = this.state;
        this.setState({ analyticsDimensions: { ...analyticsDimensions, [type]: parseInt(value) }});
    }

    getOutputTypesOptions = (): _selectoptionType[] => {
        const options: _selectoptionType[] = Object.keys(this.state.componentOutputData).map((label, index) => ({ label: `${index+1}. ${_.capitalize(label)}`, value: label }));
        return options;
    }

    getModelSummaryData = () => {
        const { outputTypesWithModelSummary,  componentOutputData } = this.state;
        if(!isEmpty(outputTypesWithModelSummary)) {
            // Object.keys(outputTypesWithModelSummary) =  [output1] / [summary1]
            const modelSummaryOutputTypeName = Object.keys(outputTypesWithModelSummary)[0] as keyof typeof componentOutputData;
            const modelSummaryInitialData = componentOutputData[modelSummaryOutputTypeName];
            if(modelSummaryInitialData){
                const outputData = modelSummaryInitialData?.data.map(__data => __data.model_summary);
                if(!isEmpty(outputData)){
                    // const modelSummaryData = JSON.parse(outputData.join(', ')) as Record<string, Record<string, any>>;
                    const modelSummaryData = JSON.parse(outputData[0]).summary as Record<string, Record<string, any>>;
                    // refer to line 63 for example of model summary data
                    return modelSummaryData;
                }
            }
        }
        return {};
    }

    getModalSummaryGraphData = () => {
        const modelSummaryData = this.getModelSummaryData();
        const graphData: {title: string; data: any; chartType: any; propertyName: string    }[] = [];
        if(!isEmpty(modelSummaryData)){
            if(_.has(modelSummaryData, 'train.feature_importances')){
                graphData.push({ title: 'Feature importances', data: modelSummaryData.train.feature_importances, chartType: 'both', propertyName: ''});
            }
            if(_.has(modelSummaryData, 'train.cluster_sizes')){
                graphData.push({ title: 'Cluster Sizes', data: modelSummaryData.train.cluster_sizes, chartType: 'both', propertyName: ''});
            }
            if(_.has(modelSummaryData, 'train.metrics.r2')){
                graphData.push({ title: 'Train - Metrics - R2', data: modelSummaryData.train.metrics.r2, chartType: 'gauge', propertyName: 'R2'});
            }
            if(_.has(modelSummaryData, 'eval.metrics.r2')){
                graphData.push({ title: 'Eval - Metrics - R2', data: modelSummaryData.eval.metrics.r2, chartType: 'gauge', propertyName: 'R2'});
            }
            if(_.has(modelSummaryData, 'train.metrics.weightedPrecision')){
                graphData.push({ title: 'Train - Metrics - Weighted Precision', data: modelSummaryData.train.metrics.weightedPrecision, chartType: 'gauge', propertyName: 'Weighted Precision'});
            }
            if(_.has(modelSummaryData, 'train.metrics.weightedRecall')){
                graphData.push({ title: 'Train - Metrics - Weighted Recall', data: modelSummaryData.train.metrics.weightedRecall, chartType: 'gauge', propertyName: 'Weighted Recall'});
            }
            if(_.has(modelSummaryData, 'eval.metrics.weightedPrecision')){
                graphData.push({ title: 'Eval - Metrics - Weighted Precision', data: modelSummaryData.eval.metrics.weightedPrecision, chartType: 'gauge', propertyName: 'Weighted Precision'});
            }
            if(_.has(modelSummaryData, 'eval.metrics.weightedRecall')){
                graphData.push({ title: 'Eval - Metrics - Weighted Recall', data: modelSummaryData.eval.metrics.weightedRecall, chartType: 'gauge', propertyName: 'Weighted Recall'});
            }


        }
        return graphData;
    }

    getStringifiedModelSummaryData =  () => {
        const modelSummaryData = this.getModelSummaryData();
        if(!isEmpty(modelSummaryData)){
            const stringifiedJson = JSON.stringify(modelSummaryData);
            return stringifiedJson;
        } return '';

    }
    // editorDidMount = (a: any, editor: any)  => {
    //     setTimeout(function() {
    //         editor.getAction('editor.action.formatDocument').run();
    //       }, 1000);
    // }

    getModelSummaryDataForTables = () => {
        const modalSummaryData = this.getModelSummaryData();
        //  refer to line 95 , value of summary data is modelSummaryData
        if(!isEmpty(modalSummaryData)) {
            const tablesToBeShown: {title: string; data: Record<string, any>}[] = [];
            Object.entries(modalSummaryData).filter(([parentName, recordsObject]) => {
                Object.entries(recordsObject).map(([name, actualRecords]) => {
                    if(name !== 'feature_importances' && name !== 'cluster_sizes') {
                        const tableData = { title: parentName + ': ' + name, data: actualRecords };
                        tablesToBeShown.push(tableData);
                    }
                });
            });
            return tablesToBeShown;
        }
        return [];
    }


    getColumnsForTable  = () => {
        const { componentOutputData, activeOutputType } = this.state;
        const graphData = componentOutputData[activeOutputType as keyof typeof componentOutputData];
        // const graphData = sampleData.output1;

        if(graphData?.schema) {
            // custom accessor is used because when field name = "depth.data", react-table is accessing it as values.depth.data instead of values["depth.data"]
            const columns = graphData.schema.fields.map((field: { name: any }) => ({Header: field.name, accessor: (d: any) => d[field.name] }));
            return columns;
        } return [];
    };

    getTableData = () => {
        const { componentOutputData, activeOutputType } = this.state;
        const graphData = componentOutputData[activeOutputType as keyof typeof componentOutputData];

        // return graphData?.data ? graphData.data : [];

        if(graphData?.data){
            const __data = graphData.data;
            const updatedData = __data.map(record => {
                const _dummy: Record<string, any> = {};
                Object.keys(record).map(key => {
                    const actualValue = record[key];
                    if(typeof(actualValue) === 'number'){
                        _dummy[key] = parseFloat((actualValue as number).toFixed(4));
                    } else if (_.isObject(actualValue) || typeof(actualValue) === 'boolean' ) {
                        _dummy[key] = JSON.stringify(actualValue).substring(0, 50);
                    } else {
                        _dummy[key] = actualValue;
                    }
                });

                return _dummy;
            });
            return updatedData;
        } return [];
    }


    runShowTillHere = () => {
        const { idOfComponentForRunTillHere } = this.state;
        const nodeId = this.props.node.getID();
        let payload = '';
        if(idOfComponentForRunTillHere !== nodeId) payload = nodeId;
        // remove selection if already selected
        _store.dispatch(setComponentIdForRunTillShow(payload) as any);
    }


    goToAnalytics = () => {
        const nodeId = this.props.node.getID();
        _store.dispatch(goToAnalyticsPage() as any);
        _store.dispatch(runAnalyticsOnActiveWorkflow(nodeId) as any);
        // _store.dispatch(setComponentIdForAnalytics(nodeId) as any);
    }

    cloneNode = () => {
        const { node } = this.props;
        const { activeExecutionEnv } = this.state;
        const { title, subtitle, actualTitle, iconKey, isPropertiesTouched, isPropertiesValid, isPropertiesSavedAtleastOnce, hash, notes, customComponentId } = node.getOptions();
        const nodeInfo: componentToBeCopiedInfo = {
            title,
            subtitle,
            actualTitle,
            iconKey,
            isPropertiesTouched,
            isPropertiesValid,
            inputPorts: node.getInPorts().length,
            outputPorts: node.getOutPorts().length,
            details: _.cloneDeep(node.extras),
            nodeType: 'component',
            env: activeExecutionEnv,
            isPropertiesSavedAtleastOnce,
            hash,
            notes,
            customComponentId
        };
        _store.dispatch(setCopiedComponentInfo(nodeInfo) as any);
    }

    handleContextMenuClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
    }

    getHoverStateInAnalyticsMode = ({ title, nodeId, className = '', Icon, subtitle = '' }: HoverStateOptions) => (
        <div className={classNames('selectToViewMsg', {[className]: className})}>
            {!!Icon && <Icon nodeId={nodeId} />}
            {/* <ShowWhenTrue show={showPlusIcon}>
                <PlusIconForAnalytics
                    nodeId={nodeId}
                />
            </ShowWhenTrue>
            <ShowWhenTrue show={showNotAvailableIcon}>
                <   UnAvailableIcon
                    nodeId={nodeId}
                />
            </ShowWhenTrue> */}
            <span className="line1">{title}</span>
            <ShowWhenTrue show={!!(subtitle)}>
                <span className="line2">{subtitle}</span>
            </ShowWhenTrue>
        </div>
    )

    refreshComponent = () => {
        _store.dispatch(workflowActionHandler({ type: "REFRESH_COMPONENT", payload: { componentId: this.props.node.getID() } }) as any)
    }

    hasDynamicPortConfig = () => {
        const currentNode = this.props.node;
        const { activeTab, openTabs } = _store.getState().CanvasReducer.workflowEditor;
        const activeTabInfo = openTabs.get(activeTab.id);
        if(activeTabInfo?.info.hasVariables) {
            return true;
        } else if(currentNode.extras.input_port || currentNode.extras.output_port) {
            // OLD STRUCTURE
            return has(currentNode.extras, 'portManagement');
        } else if(currentNode.extras.portManagement) {
            // NEW STRUCTURE
            return has(currentNode.extras.portManagement, 'fields')
                || has(currentNode.extras.portManagement, 'inputPorts.min')
                || has(currentNode.extras.portManagement, 'inputPorts.max')
                || has(currentNode.extras.portManagement, 'outputPorts.min')
                || has(currentNode.extras.portManagement, 'outputPorts.max');
        }
        return false;
    }

    handeDeleteAllNodeLinks = () => {
        this.props.node.portsIn.forEach(port => this.props.node.clearPortLinks(port));
        this.props.node.portsOut.forEach(port => this.props.node.clearPortLinks(port));
        WorkflowCanvas.repaintCanvas();
    }

    handleClickPreview = () => {
        _store.dispatch(executeActiveWorfklowStreamingPreview() as any);
    }

    addComponentToCustomComponents = () => {
        const { customComponentId } = this.props.node.getOptions();
        if(customComponentId)
            _store.dispatch(addCustomComponentToList(customComponentId) as any)
    }

    render() {
        const currentNode = this.props.node;

        const { showAnalytics, activeAnalyticsOption, previewComponentStatus, changeSuccessColorToGreen, showEditor, showRenameOptions, renameFieldValue,
            showInputPorts, analyticsDimensions, activeOutputType, componentOutputData, outputTypesWithModelSummary, modelSummarygraphType, idOfComponentForRunTillHere,
            isErrorComponentInJobRun, showJobLogsInWorkflowPage, hasActivePlotInAnalyticsPage, showPlotInProgressSpinner, numberOfActivePlots, activeExecutionEnv, isPreviewSessionCreationInProgress,
            isOptionsDropdownOpen,
        } = this.state;
        const nodeId = currentNode.getID();
        const { isPropertiesValid, isPropertiesTouched, selected: isNodeSelected, actualTitle, title, customComponentId } = currentNode.getOptions();

        const isCustomComponent = !!currentNode.extras?.customComponentPayload
        const currentPreviewStepNumber = previewComponentStatus ? previewStatusInfo[previewComponentStatus]?.step: 0;
        // const graphData = sampleGraphData.output1;
        const showModalSummaryData = outputTypesWithModelSummary[activeOutputType];
        const graphData = componentOutputData[activeOutputType as keyof typeof componentOutputData];

        // const graphData = sampleData.output1;

        const inputPorts = currentNode.getInPorts();
        const outputPorts = currentNode.getOutPorts();
        const inputPortsLength = inputPorts.length;
        const outputPortsLength = outputPorts.length;
        const isRunTillHereComponent = idOfComponentForRunTillHere === nodeId;
        const runShowHereTitle = isRunTillHereComponent ? 'Disable run until here' :'Run until here';
        const isReadOnlyNode = currentNode.extras.isReadOnly;

        let subtitle = _.startCase(currentNode.getOptions().subtitle)
        if(subtitle?.toLowerCase() === 'dag') {
            subtitle = 'DAG' 
         }

        const dropdownOptions: DropdownOptions = {
            'Manage ports': {
                action: () => _store.dispatch(toggleModal('managePorts', true) as any),
                disabled: !(this.hasDynamicPortConfig()) || isReadOnlyNode
            },
            'Summary': {
                action: () => {
                    this.handleToggleAnalytics(true);
                },
                disabled: currentPreviewStepNumber !== previewStatusInfo.success.step || isReadOnlyNode
            },
            'Visualizations': {
                action: this.goToAnalytics,
                disabled: currentPreviewStepNumber !== previewStatusInfo.success.step || isReadOnlyNode || activeExecutionEnv === ExecutionEnvModes.Streaming || isEmpty(outputPorts)
            },
            'Rename': {action: this.showRenameTitle, disabled: isReadOnlyNode},
            'Refresh': {action: this.refreshComponent, disabled: isReadOnlyNode},
            [runShowHereTitle]: {action: this.runShowTillHere, disabled: isReadOnlyNode},
            'Properties': {action: this.handleShowProperties},
            'Reset links': {action: this.handeDeleteAllNodeLinks},
            'Copy': {action: this.cloneNode},
            'Delete': {action: this.handleDeleteNode, disabled: isReadOnlyNode}
        };
        if(isCustomComponent && customComponentId) {
            dropdownOptions['Add Component'] = {action: this.addComponentToCustomComponents, disabled: isReadOnlyNode}
        }

        // @ts-ignore
        const Icon = iconList[actualTitle ||  title as string];
        const isRouteOptimisationComponent = actualTitle === "Route Optimization";
        const summaryTableData = this.getTableData();
        const isPreviewDisabledForComponent = (this.isPreviewDisabled && !!this.state.previewStatusInfo?.isRunning)
        const showActualTitle = !isCustomComponent && actualTitle !== title;
        return (
            <>
                <div
                    className={classNames('ds__node',
                        {'ds__node__invalidProps': (!isPropertiesValid && isPropertiesTouched && !isRouteOptimisationComponent)},
                        {'ds__node--active': isNodeSelected},
                        {'ds__node--analytics': showAnalytics},
                        {'progress--success': currentPreviewStepNumber === 3 },
                        {'ds__node__increaseHeight': (inputPortsLength > 4 || outputPortsLength > 4) },
                        // change above analytics to analytics summary
                        {'ds__node__analyticsPage': this.inAnalyticsMode },
                        {'ds__node__selectedComponentForAnalytics': hasActivePlotInAnalyticsPage },
                        {'ds__node__plottingInProgressSpinner': showPlotInProgressSpinner },
                        {'ds__node__disabledForAnalytics': outputPortsLength === 0,
                        'ds_node__previewDisabled': isPreviewDisabledForComponent,
                        'ds__node--options-dropdown-visible': isOptionsDropdownOpen,
                    },


                    )}
                    ref={this.nodeRef}
                    onDoubleClick={this.handleShowProperties}
                    onContextMenu={this.handleContextMenuClick}
                    role="node-component"
                    aria-label={currentNode.getOptions().title.replace(' ','')}
                    data-nodename={createNodeTestId(currentNode.getOptions().title)}
                >
                    <ShowWhenTrue show={this.inAnalyticsMode}>
                        {outputPortsLength === 0 ?
                            this.getHoverStateInAnalyticsMode({title: 'Chart unavailable for this component', nodeId, Icon: UnAvailableIcon })
                            :
                            <>
                                {hasActivePlotInAnalyticsPage &&
                                <div className="selectedComponentForAnalytics">
                                    {!showPlotInProgressSpinner &&
                                        this.getHoverStateInAnalyticsMode({ title: `${numberOfActivePlots} ${numberOfActivePlots === 1 ? 'plot': 'plots'} active`, subtitle: 'Plot another chart', nodeId, className: 'addPlotMsg' })
                                    }
                                </div>
                                }
                                {showPlotInProgressSpinner ?
                                    <div className="plottingInProgressSpinner">
                                        <InPageSpinner size="Small" />
                                    </div>
                                    :
                                    this.getHoverStateInAnalyticsMode({title: 'Select to view', nodeId, Icon: PlusIconForAnalytics })
                                }
                            </>
                        }
                    </ShowWhenTrue>
                    <ShowWhenTrue show={isPreviewDisabledForComponent}>
                        {this.getHoverStateInAnalyticsMode({title: 'Disabled during preview', nodeId, Icon: PreviewDisabledIcon, className: "previewDisabledMessage" })}

                    </ShowWhenTrue>
                    <div
                        className={classNames('ds__node__info__container',  {'ds__node--extraPadding': inputPortsLength > 0 })}
                    >
                        <ShowWhenTrue show={!!(previewComponentStatus)}>
                            {Object.entries(previewStatusInfo).map(
                                ([status, info]) => {
                                    return(
                                        <div
                                            key={status}
                                            className={classNames(`ds__node__preview__status ${info.className}`,
                                                {'active': currentPreviewStepNumber >= info.step})}
                                        >
                                            <span className="progress__box">
                                                <span className={classNames('progress', {'removeGradient--success': changeSuccessColorToGreen })} />
                                            </span>
                                        </div>
                                    );
                                }
                            )}
                        </ShowWhenTrue>
                        <ShowWhenTrue show={!isRouteOptimisationComponent}>
                            <Tooltip
                                title={!isPropertiesTouched ? 'Validation Pending: Check Properties' : 'Validation Failed: Check Properties'}
                                placement="bottom"
                            >
                                <div className={classNames('invalidPropsIcon', {'invalidPropsIcon--invalid': !isPropertiesValid}, {'invalidPropsIcon--unTouched': !isPropertiesTouched })}>
                                    <InvalidPropertiesIcon  />
                                </div>
                            </Tooltip>

                        </ShowWhenTrue> 

                        <ShowWhenTrue show={isErrorComponentInJobRun && showJobLogsInWorkflowPage}>
                            <div
                                className={classNames(`ds__node__preview__status ${previewStatusInfo[PREVIEW_FAILED].className} active`)}
                            >
                                <span className="progress__box">
                                    <span className='progress' />
                                </span>
                            </div>
                        </ShowWhenTrue>
                        <div className="ds__node__info__innercontainer" onContextMenu={(e) => {e.preventDefault();}}>
                            {Icon ?
                                <Icon />
                                :
                                <CustomComponent />
                            }
                            <div>
                                <div className={classNames('ds__node__title', {'inputfield__container':  showRenameOptions})}>
                                    {showRenameOptions ?
                                        <input
                                            className="inputfield"
                                            value={renameFieldValue}
                                            onChange={(e) => this.setState({ renameFieldValue: e.target.value })}
                                            onKeyDown={this.handleRenameFieldKeyDown}
                                            autoFocus
                                        />
                                        :
                                        <TooltipTop title={title}>
                                            <span style={{ maxWidth: showActualTitle ? this.subtitleDivRef.current?.clientWidth: 'auto' }}>
                                                {title}
                                            </span>
                                        </TooltipTop>
                                    }
                                </div>
                                <div className="ds__node__subtitle" ref={this.subtitleDivRef}>
                                    {showActualTitle && 
                                        <span>{actualTitle}&nbsp;&#183;&nbsp;</span>
                                    }
                                    <span>{subtitle}</span>
                                    {this.hasDynamicPortConfig()
                                    &&
                                    <Tooltip
                                        overlay="Manage ports"
                                        placement="bottomLeft"
                                    >
                                        <span className="portMgmt__icon">
                                            <span>.</span>
                                            <PortManagementIcon />
                                        </span>
                                    </Tooltip>
                                    }
                                </div>
                            </div>
                        </div>
                        <Dropdown
                            title={
                                <button className="ds__node__options__toggle" id = {`componentDropdown${currentNode.getOptions().title.replace(' ','')}`}>
                                    <img src="/icons/more.svg" alt="" />
                                </button>}
                            onShow={() => this.setState({isOptionsDropdownOpen: true})}
                            onHide={() => this.setState({isOptionsDropdownOpen: false})}
                            useDropdownSeparator={true}
                            indexForDropdownSeparator={3}
                            dropdownOptions = {dropdownOptions}
                            disabled={this.inAnalyticsMode}
                        />
                    </div>

                    <ShowWhenTrue show={showAnalytics}>
                        <div className="ds__node__analytics__header">
                            <span className="ds__node__graph__title">Summary</span>
                            <div className='ds__node__analyticsHeader__RHS '>
                                <ShowWhenTrue show={activeExecutionEnv === ExecutionEnvModes.Streaming}>
                                    <button
                                        onClick={this.handleClickPreview}
                                        className="btn-sm btn-yellow"
                                        id="btnNodePreview"
                                        disabled={!!this.state.previewStatusInfo?.isRunning || isPreviewSessionCreationInProgress}
                                    >
                                        {isPreviewSessionCreationInProgress ?
                                            <InPageSpinner size="XSmall" color='black' />
                                            :
                                            <>
                                                <StreamingWorkflowPlayIcon />
                                                Play
                                            </>
                                    }
                                    </button>
                                </ShowWhenTrue>
                                <img className="" src="/icons/close-options.svg" alt="" onClick={() => this.handleToggleAnalytics(false)} style={{ cursor: 'pointer' }} />

                            </div>
                        </div>
                        <div className='ds__node__analytics'>
                            <div className="analyticsCustomizationOptions__box">
                                <Form
                                    initialValues={{activeAnalyticsOption, activeOutputType, modelSummarygraphType }}
                                    onSubmit={() => {return;}}
                                    className="ds__node__graph__dropdown__box"
                                >
                                    <SelectField
                                        className="activeOutputSelectionField"
                                        name="activeOutputType"
                                        options={this.getOutputTypesOptions()}
                                        position="normal"
                                        onOptionClick={(option) => this.setState({ activeOutputType: (option.value as string) })}
                                    />
                                    {showModalSummaryData ?
                                        <SelectField
                                            label={
                                                <div className="eye__div">
                                                    <img src="/icons/Ico-Eye.svg" alt=""  />
                                                    View
                                                </div>
                                            }
                                            className="analyticsOption"
                                            name="modelSummarygraphType"
                                            options={currentNode.extras.key === 'decisionTree_2_demo' ? _.union(graphTypesOptions, [{label: 'Model', value: 'model'}]) :graphTypesOptions}
                                            // options={showModalSummaryData ? _.union(graphTypesOptions, [{label: 'Model', value: 'model'}]) :graphTypesOptions}
                                            position="normal"
                                            onOptionClick={(option) => this.setState({ modelSummarygraphType: (option.value as 'pie') })}
                                        />
                                        :
                                        <SelectField
                                            label={
                                                <div className="eye__div">
                                                    <img src="/icons/Ico-Eye.svg" alt=""  />
                                                    View
                                                </div>
                                            }
                                            className="analyticsOption"
                                            name="activeAnalyticsOption"
                                            options={analyticsOptions}
                                            position="normal"
                                            onOptionClick={(option) => this.setState({ activeAnalyticsOption: (option.value as string) })}
                                        />


                                    }
                                </Form>
                                <ShowWhenTrue show={!showModalSummaryData}>
                                    <div className="inputfield__container">
                                        <div>
                                            <label className="inputfield__label">Width</label>
                                            <input className="inputfield" value={analyticsDimensions.width} min="0" type="number" onChange={(e) => this.handleDimensionChange('width', e.target.value)} />
                                        </div>
                                        <div>
                                            <label className="inputfield__label">Height</label>
                                            <input className="inputfield" value={analyticsDimensions.height} min="0" type="number" onChange={(e) => this.handleDimensionChange('height', e.target.value)} />
                                        </div>
                                    </div>
                                </ShowWhenTrue>
                            </div>

                            <ShowWhenTrue show={showModalSummaryData}>
                                {/* For Model Summary */}
                                {showEditor ?
                                    <div style={analyticsDimensions} className="editor__box">
                                        <button
                                            className="modalSummary__btn"
                                            onClick={() => this.setState({ showEditor: false })}
                                        >
                                        Go back
                                        </button>
                                        <Editor
                                            theme="dark"
                                            language="json"
                                            value={this.getStringifiedModelSummaryData()}
                                        // editorDidMount={() => this.editorDidMount.bind(this)}
                                        // options={{ readOnly: true }}
                                        />
                                    </div>
                                    :
                                    <>
                                        <button
                                            className="modalSummary__btn"
                                            onClick={() => this.setState({ showEditor: true })}
                                        >
                                        View Raw JSON
                                        </button>
                                        {modelSummarygraphType === 'model' as any ?
                                            <div className="modalSummary__tables__box" >
                                                <TableForModelSummary
                                                // title={_table.title}
                                                    title="Model Equation"
                                                    data={{
                                                        'Equation': 'IF(\'VR\'<0.643_OR_\'VR\'>=1.425)_THEN_0_ELSE_(1.554-1.156*\'PGrad\')*(0.049*\'Thickness\'*\'Porosity\'*(1-\'Sw\')/\'PGrad\')',
                                                        'Accuracy': 0.874
                                                    }}
                                                    style={{ maxWidth: 'max-content' }}
                                                />
                                            </div>
                                            :
                                            <ShowWhenTrue show={!isEmpty(this.getModalSummaryGraphData())}>
                                                <div className="modalSummary__graphs__box">
                                                    {this.getModalSummaryGraphData().map((_graph, index) => (
                                                        <GraphsForModelSummary
                                                            key={_graph.title+index}
                                                            analyticsDimensions={analyticsDimensions}
                                                            title={_graph.title}
                                                            data={_graph.data}
                                                            modelSummarygraphType={_graph.chartType === 'both' ? modelSummarygraphType: 'gauge'}
                                                            propName={_graph.propertyName}
                                                        />
                                                    ))
                                                    }
                                                </div>
                                            </ShowWhenTrue>

                                        }


                                        <div className="modalSummary__tables__box">
                                            {this.getModelSummaryDataForTables().map(_table => (
                                                <TableForModelSummary
                                                    key={_table.title}
                                                    title={_table.title}
                                                    data={_table.data}
                                                />
                                            ))}
                                        </div>

                                    </>
                                }
                            </ShowWhenTrue>
                            <ShowWhenTrue show={!showModalSummaryData}>
                                {
                                    isEmpty(summaryTableData) && activeExecutionEnv === ExecutionEnvModes.Streaming && outputPortsLength !== 0 ?

                                    <div className='noDataMessage__streamingPreview' 
                                        style={analyticsDimensions}
                                    
                                    >Workflow did not collect any data in the prescribed time, please wait longer or check if new data is expected from the source</div>
                                    :
                                    <>
                                    {activeAnalyticsOption === AnalyticsTypes.table &&
                                        <Table
                                            headers={this.getColumnsForTable()}
                                            data={summaryTableData}
                                            analyticsDimensions={analyticsDimensions}
                                            show={activeAnalyticsOption === AnalyticsTypes.table}
                                            className={activeOutputType.includes('summary') ? 'summaryTable': ''}
                                        />
                                        }
                                        <AnalyticsGraphs
                                            graphData={graphData}
                                            analyticsDimensions={analyticsDimensions}
                                            show={showModalSummaryData ? true : activeAnalyticsOption === AnalyticsTypes.customizePlot}
                                        />
                                    </>
                                }
                                
                            </ShowWhenTrue>
                        </div>
                    </ShowWhenTrue>
                    <div className="ds__node__ports--in">
                        <ShowWhenTrue show={inputPorts.length > 1}>
                            <div className="ds__node__port ds__node__port--dummy">
                                <div className="port__innerDot" />
                            </div>
                        </ShowWhenTrue>
                        {inputPortsLength === 1 ? (
                            inputPorts[0] ? this.generatePort(inputPorts[0], -1, 1) : null
                        ) : (
                            inputPorts.map((port: DsPortModel, portNumber) =>
                                port ? (
                                    <React.Fragment key={port.getID() + port.getName()}>
                                        {this.generatePort(port, portNumber, inputPortsLength as totalPortsNumber)}
                                    </React.Fragment>
                                ) : null
                            )
                        )}

                        <svg
                            className={classNames('svg__externalPortsBox', {'offsetPath__duringIncreasedHeight': inputPortsLength < 5}, {'hide': inputPortsLength <= 1})}
                            onMouseOver={(e) => e.stopPropagation()}
                        >
                            <path d={pathConnectingPorts[inputPortsLength as 2 | 3 | 4]} strokeWidth="1.5" stroke="#2A2C42" fill="none" />
                        </svg>

                        <ShowWhenTrue show={inputPortsLength > 7}>
                            <svg
                                className={classNames('svg__externalPortsBox svg__portsCircle', {'offsetPath__duringIncreasedHeight': inputPortsLength < 5}, {'hide': inputPortsLength <= 1})}
                                onMouseOver={(e) => e.stopPropagation()}
                            >
                                <circle cx="69" cy="58" r="49" strokeWidth="1.5" stroke="#2A2C42" fill="rgb(3 6 19)" />
                            </svg>
                        </ShowWhenTrue>
                    </div>
                    <div className="ds__node__ports--out">
                        <ShowWhenTrue show={outputPortsLength > 1}>
                            <div className="ds__node__port ds__node__port--dummy">
                                <div className="port__innerDot" />
                            </div>
                        </ShowWhenTrue>
                        {outputPortsLength === 1 ?
                            this.generatePort(outputPorts[0], -1, 1)
                            :
                            <ShowWhenTrue show={showInputPorts}>
                                {outputPorts.map((port: DsPortModel, portNumber,) =>
                                    <React.Fragment key={port?.getID()+port?.getName()}>
                                        {this.generatePort(port, portNumber, outputPortsLength as totalPortsNumber, true)}
                                    </React.Fragment>
                                )}
                                <svg
                                    className={classNames('svg__externalPortsBox', {'offsetPath__duringIncreasedHeight': outputPortsLength < 5}, {'hide': outputPortsLength <= 1})}
                                    onMouseOver={(e) => e.stopPropagation()}
                                >
                                    <path d={pathConnectingPorts[outputPortsLength as 2 | 3 | 4]} strokeWidth="1.5" stroke="#2A2C42" fill="none" />
                                </svg>
                            </ShowWhenTrue>
                        }
                    </div>


                </div>
                <ShowWhenTrue show={isRunTillHereComponent}>
                    <div
                        className="ds_node__runUntilHereText"
                    >
                    RUN UNTIL HERE
                    </div>
                </ShowWhenTrue>
            </>
        );
    }
}
