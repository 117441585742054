import React, { useEffect, useMemo, useState } from 'react';
import Form, { InputField } from '../../../../components/form';
import * as yup from 'yup';
import { SelectField, _selectoptionType } from '../../../../components/form/select-field';
import styles from '../../styles.module.scss';
import { DataSourceHandler } from '@api/data-source-handler';
import { Select } from 'antd';
const { Option } = Select;

const nifiFormSchema = yup.object({
    edgeName: yup.string().required('This is a required field'),
    accessKey: yup.string().required('This is a required field'),
    accessKeyConfirm: yup.string().required('This is a required field').oneOf([yup.ref('accessKey'), null], 'AccessKey must match'),
    nodeKind: yup.string().required('This is a required field'),
    sinkInformation: yup.string(),
    sinkKind: yup.string()
});

export type NifiFormValues = yup.InferType<typeof nifiFormSchema>

export type UserFormProps = {
    type: 'UPDATE' | 'ADD';
    initialValues: any;
    submitButtons?: React.ReactNode;
    onSubmit?: (arg0: NifiFormValues) => any;
}



export const NifiForm: React.FC<UserFormProps> = ({ onSubmit, type, initialValues, submitButtons}) => {
    const [nodeKindOptions, setNodeKindOptions] = useState<_selectoptionType[]>([]);
    const [sinkOptions, setSinkOptions] = useState<_selectoptionType[]>([]);
    const handleFormSubmit = (values: NifiFormValues) => {
        const submitValues = {
            ...values,
            sinkKind: selectedSinkKind || '' 
        };
        onSubmit && onSubmit(submitValues);
    };
    const [selectedNodeKind, setSelectedNodeKind] = useState<string>(''); 
    const [selectedSinkKind, setSelectedSinkKind] = useState<string>(''); 
    const [selectedSinkName, setSelectedSinkName] = useState<string>(''); 
    const initialValuesForForm: NifiFormValues = useMemo(() => {
        return { 
            edgeName: initialValues?.nodeName ||'',  
            accessKey: initialValues?.accessKey ||'', 
            accessKeyConfirm:'',
            nodeKind: initialValues?.nodeKind || selectedNodeKind,
            sinkInformation: initialValues?.sinkInformation || selectedSinkName,
            sinkKind: selectedSinkKind || ''
        };
    }, [initialValues, type, selectedSinkKind]);

    useEffect(() => {
        DataSourceHandler.GetNodeKind((res) => {
            setNodeKindOptions(res.map((_res: any) => {
                return {
                    label: _res,
                    value: _res
                }
            }))
        });
    },  []);

    useEffect(() => {
            DataSourceHandler.GetSinkNodes((res) => {
                setSinkOptions(
                    res.map((_res: any) => {
                        return {
                            label: _res.sinkName,
                            value: _res.sinkKind,
                        };
                    })
                );
            });
    }, [selectedNodeKind]);

    return(
        <Form
            initialValues={initialValuesForForm}
            validationSchema={nifiFormSchema}
            onSubmit={handleFormSubmit}
            enableReinitialize
        >
            <div
                className={styles['userInfoForm']}
            >
                <InputField 
                    name="edgeName"
                    label="Node Name"
                    required={true}
                    autoComplete="off"
                    disabled={type === 'UPDATE'}
                />
                <SelectField
                    name="nodeKind"
                    label="Node Kind"
                    options={nodeKindOptions}
                    required={true}
                    className='mb-0'
                    onOptionClick={(nodeKindOptions) => {
                        setSelectedNodeKind(nodeKindOptions.value);
                    }}
                />
                {(selectedNodeKind === 'OPCUA' && type === 'ADD') || type === 'UPDATE' ? (
                    <SelectField
                        name="sinkInformation"
                        label="Choose Sink"
                        options={sinkOptions}
                        initial_value={initialValuesForForm.sinkInformation}
                        className="mb-0"
                        required={true}
                        onOptionClick={(sinkOption) => {
                            setSelectedSinkName(sinkOption.label); 
                            setSelectedSinkKind(sinkOption.value);
                        }}
                    />
                ) : null}

                <InputField 
                    name="accessKey"
                    label="Access Key"
                    type="password"
                    required={true}
                    autoComplete="off"
                />
                <InputField 
                    name="accessKeyConfirm"
                    label="Confirm Access Key"
                    required={true}
                    type="password"
                    autoComplete="off"
                />
                <div className={styles['divider']}></div>
            </div>
            {!!submitButtons && submitButtons}
        </Form>
    );
};

