import React, { useState, useRef, useMemo, useEffect } from 'react';
import TreeMenu, { TreeNodeInArray } from 'react-simple-tree-menu';
import classNames from 'classnames';
import { useInterval } from 'rooks';
import { DataSourceHandler, ConnectionInfo, AllConnectionsInfo, WitsmlWellsInfo, WitsmlWellBoresInfo, WitmslDataObjectsInfo, WitsmlDataObjectResponse, NiFiManagedNodesResponse, NiFiManagedConnectionInfo, NiFiManagedNode } from '../../../api/data-source-handler';
import { ItemComponent, Item } from './treeview-item';
import { NewEditConnectionModal, NewEditConnectionProps } from '../modals/new-edit-connection';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDataBrowserModal } from '../../../store/datasources';
import { ContextMenuComponent } from '../../../components/context-menu';
import { ShowWhenTrue } from '../../../helpers';
import { omit, isEmpty } from 'lodash';
import { DeleteConnectionModal } from '../modals/delete-connection';
import { plainToFlattenObject } from '../../../helpers/utils';
import { errorAlert, infoAlert, successAlert } from '../../../components/toastify/notify-toast';
import { Env } from '@constants/settings';
import { SourceTypes, ConnectionTypes } from '../enums';
import { getLoadingNode, handleAddNodeToTreeViewData, parseConnectionsResponseIntoNewFormat, parseConnectionsResponseIntoOldFormat } from '../utils';
import { OldNewEditConnectionModal } from '../modals/old-new-edit-connection';
import { Menu, Dropdown as AntDropdown } from 'antd';
import { setSingleItemInfoForModals, setActiveSelectionType, toggleModal, ModalTypes } from '../../../store/workflow';
import { ShareWorkflowModal } from '../../workflow-page/modals/share-workflow';
import { RootState } from '@store/types';

export type CustomTreeNodeInArray = TreeNodeInArray & { id?: number | string };
export type TableInfo = ConnectionInfo & CustomTreeNodeInArray & Item;
export type TableMetaData = { connectionId: number; tableName: string; connectionName: string; connectionType?: string; sourceType: string; managedNodeInfo ?: NiFiManagedNode; nodeName?:string };


interface Props {
    handleGetRecords: (arg0: TableMetaData) => any;
    handleResetRecords: () => any;
    handleNodeUpdate: (nodes: any) => void;
}

const convertEdgeConnectionsToTreeview = (edgeConns: NiFiManagedConnectionInfo[], sourceType: SourceTypes) => 
    edgeConns.map((connectionInfo) => ({
        ...connectionInfo,
        managedNodeInfo: connectionInfo.node,
        key: ConnectionTypes['NiFi Managed'] + '_' + connectionInfo.platformType + '_' + sourceType + '_' + connectionInfo.id + '_' + connectionInfo.name,
        label: `${connectionInfo.name} (${connectionInfo.node.nodeName})`,
        connectionType: ConnectionTypes['NiFi Managed'],
        sourceType
    }))
    

export const ConnectionsTreeview: React.FC<Props> = ({ handleGetRecords, handleResetRecords, handleNodeUpdate }) => {
    const [searchInput, setSearchInput] = useState('');
    const [newWorkFlow, setNewWorkFlow] = useState<boolean>(false);
    const [dataForTreeview, setDataForTreeview] = useState<CustomTreeNodeInArray[]>([]);
    const dispatch = useDispatch();
    const contextMenuRef = useRef<ContextMenuComponent>(null);
    const [activeContextMenuData, setActiveContextMenuData] = useState<any>({});
    const [actionType, setActionType] = useState<NewEditConnectionProps['actionType']>('NEW');
    const connectionsList = useRef<CustomTreeNodeInArray[]>([]);
    const useOldDatabrowserConnections = Env.useOldDatabrowserConnections;
    const { actionType: action } = useSelector((store: RootState) => store.NewDataSourceReducer);

    const handleTreeViewSearch = (searchFunc: any, searchString: string) => {
        searchFunc && searchFunc(searchString);
        setSearchInput(searchString);
    };    

    
    const addNodeToTreeViewData = (nodeIdentifier: string, dataToBeAdded: CustomTreeNodeInArray[]) => {
        setDataForTreeview(dataForTreeview => handleAddNodeToTreeViewData(dataForTreeview, nodeIdentifier, dataToBeAdded))
    }

    
    const handleGetTablesResponse = (props: TableInfo, response: string[]) => {
        const tablesData = response.map((_table, index) => ({ key: _table + index, label: _table, connectionId: props.id, connectionName: props.name, connectionType: props.connectionType }));
        addNodeToTreeViewData(props.key, tablesData);
    };

    const handleGetTablesErrorResponse = (props: TableInfo,error:any) => {
        addNodeToTreeViewData(props.key,[{label: 'error', key: '__error'}])
        errorAlert(error?.response.data);
    };

    const handleGetVersionsResponse = (props: TableInfo, response: string[]) => {
        const versionsInfo = response.map(_table => ({ 
            key: _table, 
            label: _table, 
            connectionId: props.id, 
            connectionName: props.name, 
            connectionType: 'WITSML',
            nodes: getLoadingNode()
        }));
        addNodeToTreeViewData(props.key, versionsInfo);
    };

    const handleGetWellsResponse = (props: TableInfo, response: WitsmlWellsInfo) => {
        const wellsInfo = response.wells.well.map(well => ({ 
            key: (well.name+well.uid).replace('/', ''), 
            label: well.name, 
            connectionId: props.connectionId, 
            connectionName: props.connectionName, 
            wellId: well.uid, 
            connectionType: 'WITSML',
            version: props.label,
            nodes: getLoadingNode() 
        }));
        addNodeToTreeViewData(props.key, wellsInfo);
    };
    const handleGetWellsErrorCallback = (props: TableInfo, errResponse: any) =>{
        errorAlert(errResponse.data);
        addNodeToTreeViewData(props.key, []);
    };

    const handleGetWellBoresResponse = (props: TableInfo, response: WitsmlWellBoresInfo) => {
        const wellBoresInfo = response.wellbores.wellbore.map((wellBore,index) => ({ 
            key: (wellBore.name+wellBore.uid+index).replace('/', ''), 
            label: wellBore.name, 
            connectionId: props.connectionId, 
            connectionName: props.connectionName, 
            wellBoreId: wellBore.uid, 
            wellId: wellBore.uidWell,
            connectionType: 'WITSML',
            version: props.version,
            nodes: getLoadingNode() 
        }));
        addNodeToTreeViewData(props.key, wellBoresInfo);
    };

    const handleGetDataObjects = (props: TableInfo, response: WitmslDataObjectsInfo) => {
        const dataObjectsInfo = response.dataObject.map((dataObject,index) => ({ 
            key: (dataObject+props.wellBoreId+index).replace('/', ''), 
            label: dataObject, 
            connectionId: props.connectionId, 
            connectionName: props.connectionName, 
            wellBoreId: props.wellBoreId, 
            wellId: props.wellId,
            connectionType: 'WITSML',
            version: props.version,
            // nodes: getLoadingNode() 
        }));
        addNodeToTreeViewData(props.key, dataObjectsInfo);
    };

    const handleGetDataObjectInfo = (props: TableInfo, response: WitsmlDataObjectResponse) => {
        // {
        //     "fluidsReports": {
        //         "xmlns": "http://www.witsml.org/schemas/131",
        //         "fluidsReport": Record<any>[] | Record<any>,
        // "version": "1.3.1.1"
        //     }
        // }
        const actualData = omit(Object.values(response)[0], 'version', 'xmlns');
        if(!isEmpty(actualData)) {
            let dataObjectInfo = Object.values(actualData)[0];
            if(Array.isArray(dataObjectInfo)) {
                const allKeysObj: Record<string, true> = {};
                dataObjectInfo = dataObjectInfo
                    .map(obj => {
                        const modifiedObj = plainToFlattenObject(obj);
                        Object.keys(modifiedObj).forEach(key => allKeysObj[key] = true);
                        return modifiedObj;
                    });
                const keysList = Object.keys(allKeysObj);
                // there can be missing keys in the data, this makes sure every object in the array has those keys
                dataObjectInfo = dataObjectInfo
                    .map((obj: Record<string, any>) => {
                        const newObj: Record<string, any> = {};
                        keysList.forEach(key => {
                            newObj[key] = obj[key] || '';
                        });
                        return newObj;
                    });
            } else {
                dataObjectInfo = plainToFlattenObject(dataObjectInfo);
            }
        } else {
            infoAlert('No Data found');
        }
    };



    // const handleGetContainersListResponse = (props: TableInfo ,containersList: string[]) => {
    //     const _containers = containersList.map((cont, index) => ({
    //         key: (props.name+cont+index), 
    //         label: cont, 
    //         connectionId: props.id, 
    //         connectionName: props.name, 
    //         connectionType: 'AzureBlobStorage',
    //         nodes: getLoadingNode() 
    //     }));
    //     addNodeToTreeViewData(props.key, _containers);
    // };

    // const handleBlobsOfContainerResponse = (props: TableInfo, blobsList: string[]) => {
    //     const _blobs = blobsList.map((blob, index) => ({
    //         key: (props.name+blob+index), 
    //         label: blob, 
    //         connectionId: props.connectionId, 
    //         connectionName: props.connectionName, 
    //         connectionType: 'AzureBlobStorage',
    //         nodes: []
    //     }));
    //     addNodeToTreeViewData(props.key, _blobs);
    // };
    
    
    const showNewEditConnectionModal = (action: typeof actionType) => {
        setActionType(action);
        dispatch(toggleDataBrowserModal('newEditConnection', true));
    };

    const showDeleteconnectionModal = () => {
        dispatch(toggleDataBrowserModal('deleteConnection', true));
    };

    function handleItemClickInOldConnectionList(this: any, props: TableInfo) {
        if (!props.isOpen && props.level !== 0) {
            if(
                (props.connectionType === 'OPCUA' && props.level === 2) ||
                ((props.connectionType === 'API' || props.connectionType === 'AzureBlobStorage') && props.level === 1) || 
                (props.connectionType === 'PI')) {
                handleResetRecords();
                setActiveContextMenuData(props);
                showNewEditConnectionModal('EDIT');
            }
            else {
                switch (props.level) {
                    case 1:
                        if(props.connectionType === 'WITSML') 
                            DataSourceHandler.GetVersions({ witsmlDataSourceId: props.id.toString() }, handleGetVersionsResponse.bind(this, props));
                            break;  
                    case 2:
                        if(props.connectionType === 'WITSML')   
                            DataSourceHandler.GetWells({ witsmlDataSourceId: props.connectionId, version: props.label }, handleGetWellsResponse.bind(this, props),handleGetWellsErrorCallback.bind(this, props));
                        // else if(props.connectionType === 'AzureBlobStorage') 
                        //     DataSourceHandler.GetAzureBlobsOfContainer(props.connectionId, props.label, handleBlobsOfContainerResponse.bind(this, props));
                        else 
                            DataSourceHandler.GetTables({ connectionId: props.id, schemaName: props.databaseName }, handleGetTablesResponse.bind(this, props),handleGetTablesErrorResponse.bind(this,props));
                        break;
                    case 3:
                        if(props.connectionType === 'WITSML')  
                            DataSourceHandler.GetWellBores({ witsmlDataSourceId: props.connectionId, version: props.version, wellUids: [props.wellId] }, handleGetWellBoresResponse.bind(this, props));
                        else if(props.connectionType !== 'AzureBlobStorage')
                            handleGetRecords({ connectionId: props.connectionId, tableName: props.label, connectionName: props.connectionName, sourceType: '' });
                        // DataSourceHandler.GetRecords({ connectionId: props.connectionId, tableName: props.label, pageNo: 1, pageSize: 15 }, handleSetRecordsInfo);
                        break;
                    case 4:
                        DataSourceHandler.GetDataObjects({ witsmlDataSourceId: props.connectionId, version: props.version }, handleGetDataObjects.bind(this, props));
                        break;
                    case 5:
                        DataSourceHandler
                            .GetDataObjectInfo(props.label, { 
                                wellAndWellboreUids: [{ uidWell: props.wellId,  uidWellbore: props.wellBoreId} ], 
                                witsmlDataSourceId: props.connectionId, version: props.version,
                            }, handleGetDataObjectInfo.bind(this, props));
                        break;
                    default:
                        break;
                }
            }
        }
    }


    

    function handleItemClickInNewConnectionList(this: any, props: TableInfo) {
        if (!props.isOpen && props.level > 2) {
            // if(
            //     (props.connectionType === 'OPCUA' && props.level === 2) ||
            //     ((props.connectionType === 'API' || props.connectionType === 'AzureBlobStorage') && props.level === 1) || 
            //     (props.connectionType === 'PI')) {
            //     handleResetRecords();
            //     setActiveContextMenuData(props);
            //     showNewEditConnectionModal('EDIT');
            // }
            if(props.connectionType==='Edge_Managed_FTP' || props.connectionType === 'Edge_Managed_Oracle' || props.connectionType === 'WITSML' || props.connectionType === 'MySql' || props.connectionType === 'SQLServer' || props.connectionType === ConnectionTypes['NiFi Managed'] || props.connectionType === ConnectionTypes['Snowflake']) {
                switch (props.level) {
                    case 2:
                        if(props.connectionType === 'WITSML') 
                            DataSourceHandler.GetVersions({ witsmlDataSourceId: props.id.toString() }, handleGetVersionsResponse.bind(this, props));
                    
                        // else if(props.connectionType === 'AzureBlobStorage') 
                        //     DataSourceHandler.GetContainersListInAzureBlobConn(props.id, handleGetContainersListResponse.bind(this, props));
                        break;
                    case 3:
                        if(props.connectionType === 'WITSML')   
                            DataSourceHandler.GetWells({ witsmlDataSourceId: props.connectionId, version: props.label }, handleGetWellsResponse.bind(this, props),handleGetWellsErrorCallback.bind(this, props));
                        // else if(props.connectionType === 'AzureBlobStorage') 
                        //     DataSourceHandler.GetAzureBlobsOfContainer(props.connectionId, props.label, handleBlobsOfContainerResponse.bind(this, props));
                        
                        else if(props.connectionType === ConnectionTypes['NiFi Managed'] || props.connectionType == 'Edge_Managed_Oracle' || props.connectionType==='Edge_Managed_FTP') 
                            handleGetRecords({ 
                                connectionId: props.id,
                                connectionName: props.label,
                                tableName: props.label,
                                connectionType: props.connectionType,
                                sourceType: props.sourceType,
                                managedNodeInfo: props.managedNodeInfo?props.managedNodeInfo:props.node,
                                nodeName: props.nodeName
                            });
                        else 
                            DataSourceHandler.GetTables({ connectionId: props.id, schemaName: props.databaseName }, handleGetTablesResponse.bind(this, props),handleGetTablesErrorResponse.bind(this,props));
                        break;
                    case 4:
                        if(props.connectionType === 'WITSML')  
                            DataSourceHandler.GetWellBores({ witsmlDataSourceId: props.connectionId, version: props.version, wellUids: [props.wellId] }, handleGetWellBoresResponse.bind(this, props));
                        // else if(props.connectionType !== 'AzureBlobStorage')
                        else
                            handleGetRecords({ 
                                connectionId: props.connectionId, 
                                tableName: props.label, 
                                connectionName: props.connectionName, 
                                sourceType: props.sourceType 
                            });
                        // DataSourceHandler.GetRecords({ connectionId: props.connectionId, tableName: props.label, pageNo: 1, pageSize: 15 }, handleSetRecordsInfo);
                        break;
                    case 5:
                        DataSourceHandler.GetDataObjects({ witsmlDataSourceId: props.connectionId, version: props.version }, handleGetDataObjects.bind(this, props));
                        break;
                    case 6:
                        DataSourceHandler
                            .GetDataObjectInfo(props.label, { 
                                wellAndWellboreUids: [{ uidWell: props.wellId,  uidWellbore: props.wellBoreId} ], 
                                witsmlDataSourceId: props.connectionId, version: props.version,
                            }, handleGetDataObjectInfo.bind(this, props));
                        break;
                    default:
                        break;
                }
            } else {
                handleResetRecords();
                setActiveContextMenuData(props);
                showNewEditConnectionModal('EDIT');
            }
        }
    }

    

    // const retrievePiNodes = () => {
    //     DataSourceHandler.GetNodesOfNiFiManaged((res) => {
    //         res.OSIPI?.Edge_Managed && addNodeToTreeViewData("Generic/Generic_OSIPI/Edge_Managed_OSIPI_Generic", convertEdgeConnectionsToTreeview(res.OSIPI.Edge_Managed, SourceTypes['OSIPI']))
    //         res.OPCUA?.Edge_Managed && addNodeToTreeViewData("Generic/Generic_OPCUA/Edge_Managed_OPCUA_Generic", convertEdgeConnectionsToTreeview(res.OPCUA.Edge_Managed, SourceTypes['OPCUA']))
    //         res.OPCHDA?.Edge_Managed && addNodeToTreeViewData("Generic/Generic_OPCHDA/Edge_Managed_OPCHDA_Generic", convertEdgeConnectionsToTreeview(res.OPCHDA.Edge_Managed, SourceTypes['OPCHDA']))
    //         res.AspenIP21?.Edge_Managed && addNodeToTreeViewData("Generic/Generic_AspenIP21/Edge_AspenIP21_Generic", convertEdgeConnectionsToTreeview(res.AspenIP21.Edge_Managed, SourceTypes['AspenIP21']))
    //         handleNodeUpdate(res)
    //     })
    // }

    const handleConnectionsResponse = (connections: AllConnectionsInfo) => {
        let parsedConnectionData: CustomTreeNodeInArray[] = [];
        try {
            if(useOldDatabrowserConnections) {
                parsedConnectionData = parseConnectionsResponseIntoOldFormat(connections as any)
            } else {
                parsedConnectionData = parseConnectionsResponseIntoNewFormat(connections)
            }
        } catch(e) {
            /* eslint-disable no-console */
            console.log(e)
            errorAlert("Error loading connections")
        }
    

        setDataForTreeview(parsedConnectionData);

        if(Env.showPiRequestsTable) {
            connectionsList.current = parsedConnectionData;
            // retrievePiNodes();
        } 
    };

    const getAllConnections = () => {
        DataSourceHandler.GetAllConnectionsInfo(handleConnectionsResponse);
        getAllStausConnections();
    };

    // const  { start, stop } = useInterval(retrievePiNodes, 15000)
    const getAllStausConnections = () => {
        DataSourceHandler.GetAllConnectionsInfo((res) => {
            handleNodeUpdate(res);
        });
    };


    useEffect(() => {
        getAllConnections();
        // if(Env.showPiRequestsTable) {
        //     start();
        // }

		return () => {
            if(Env.showPiRequestsTable) {
			    stop();
            }
		};
	}, []);



    const connectionDataForModals = useMemo(() => omit(activeContextMenuData, 
        'index', 'isOpen', 'key', 'level', 'hasNodes', 'onClick', 'openNodes', 'parent', 'searchTerm', 'toggleNode')
    , [activeContextMenuData]);

    const NewEditConnectionModalWrapper = useOldDatabrowserConnections ? OldNewEditConnectionModal:NewEditConnectionModal; 

    const cloneConnectionClick = (connectionId: number,connectionName:string) => {
        connectionName && infoAlert('Cloning ' + connectionName);
        DataSourceHandler.CloneConnectionRequest(connectionId, (response:string) => {
            DataSourceHandler.GetAllConnectionsInfo(handleConnectionsResponse);
			successAlert(response);
		});
    };

    const handleSingleConnectionSelection = (props:any) => {
         dispatch(setActiveSelectionType('single'));
         dispatch(setSingleItemInfoForModals({ id: props.id, name: props.label, version: 1 }));
    };

    function handleToggleModal(name: ModalTypes, action: boolean) {
        dispatch(toggleModal(name, action));
    }

    return (
        <div className="connections__treeview">
            <TreeMenu
                data={dataForTreeview}
                onClickItem={useOldDatabrowserConnections ? handleItemClickInOldConnectionList: handleItemClickInNewConnectionList as any}
                matchSearch={({ searchTerm, label }) => 
                    (label !== 'loading' && label?.toLowerCase().includes(searchTerm.toLowerCase()))
                }
                hasSearch
            >
                {({ search, items, searchTerm }) => (
                    <>
                        <div className="search__input__container">
                            <input
                                onChange={e => handleTreeViewSearch(search, e.target.value)}
                                className="search_input"
                                placeholder="Try 'connection'"
                                value={searchInput}
                            />
                            {!!(searchTerm) && <img src="/icons/treeview/search-cross.svg" alt="" className="search_reset" onClick={() => handleTreeViewSearch(search, '')} />}
                        </div>
                        <div className="newBtn__box">
                            <button 
                                id="Btn_NewConnection"
                                className="btn-sm btn-yellow-transparent btn__new__workflow btn__new__action" 
                                onClick={() => showNewEditConnectionModal('NEW')}
                            >
                                <img src="/icons/workflow/add.svg" alt="" /> 
                                <span>New Connection</span>
                            </button>
                        </div>
                        <div className="treeview__list">
                           {items.map(({ key, isConnection, ...props }) => (
                                <div
                                    className={classNames(
                                        'treeitem__container',
                                        { 
                                            'treeitem__heading': props.level === 0,
                                            'treeitem__table':  props.level ===  (useOldDatabrowserConnections ? 3: 4),
                                            'treeitem__connection': isConnection,
                                            'treeitem__loading': props.label === 'loading'
                                        },
                                        // { 'treeitem__activeContextMenu': key === activeContextMenuData.key && contextMenuRef.current?.isOpen() },
                                    )}
                                    key={key + 1}
                                    onContextMenu={(e) => {
                                        e.preventDefault();
                                        if(isConnection) {
                                            contextMenuRef.current ?.showContextMenu(e);
                                            setActiveContextMenuData({ key, ...props });
                                        }
                                        // if(props.level !== 0 && !key.includes('noDataFound') && !key.includes('Loading')) {
                                        //     setActiveContextMenuData(props as any);
                                        //     contextMenuRef.current?.showContextMenu(e);
                                        // } else {
                                        //     e.preventDefault();
                                        // }
                                    }}
                                >
                                    <ItemComponent
                                        key={key}
                                        className='treeview__item'
                                        {...props}
                                    >
                                        <ShowWhenTrue show={!!(isConnection)}>
                                            <div className="hoverIcons">
                                                <AntDropdown
                                                    overlay={<Menu
                                                        className="workflowItem__contextMenuOptions"
                                                    >
                                                        <Menu.Item
                                                            onClick={() =>  cloneConnectionClick(props.id,props.label)}
                                                            className="btn__copyConnectionIcon"
                                                            id="copy"
                                                        >
                                                            Copy
                                                        </Menu.Item> 
                                                        <Menu.Item
                                                            onClick={() => {
                                                                setActiveContextMenuData({ key, ...props });
                                                                showNewEditConnectionModal('EDIT');
                                                                props.toggleNode?.();
                                                            }}
                                                            className="btn__editConnectionIcon"
                                                            id="edit"
                                                        >
                                                            Edit
                                                        </Menu.Item>
                                                        <Menu.Item
                                                            onClick={() => {
                                                                setActiveContextMenuData({ key, ...props });
                                                                showDeleteconnectionModal();
                                                            }}
                                                            className="btn__deleteConnectionIcon"
                                                            id="delete"
                                                        >
                                                            Delete
                                                        </Menu.Item>
                                                        <Menu.Item
                                                           onClick={() => {
                                                                 handleSingleConnectionSelection({...props});
                                                                 handleToggleModal('shareWorkflow', true);
                                                                 setNewWorkFlow(true)
                                                               }}
                                                              >
                                                           Share a copy
                                                        </Menu.Item>
                                                    </Menu>}
                                                    trigger={['click']}
                                                    openClassName="showHoverState"
                                                >
                                                    <div
                                                        id={`Workflow_dropDownDots_${key}`}
                                                        className="dropdown__threedots"
                                                    >
                                                        <img id={`Workflow_dropDownDots_Img`} src="/icons/more.svg" alt="" />
                                                    </div>
                                                </AntDropdown>
                                            </div>
                                        </ShowWhenTrue>
                                    </ItemComponent>
                                </div>
                            ))}
                            <ContextMenuComponent
                                ref={contextMenuRef}
                                className="canvasContextMenu connectionsContextMenu"
                                useWindowAsReference                        
                            >
                                <button
                                    onClick={() => showNewEditConnectionModal('EDIT')}
                                >
                                    Edit
                                </button>
                                <button 
                                    onClick={showDeleteconnectionModal}
                                >
                                    Delete
                                </button>
                            </ContextMenuComponent>
                        </div>
                    </>
                )}
            </TreeMenu>
            <NewEditConnectionModalWrapper
                onCreateConnSuccess={getAllConnections}
                actionType={action=='NEW'?action:actionType}
                connectionDataForEdit={action=='NEW'?{}:connectionDataForModals}
            />
            <DeleteConnectionModal 
                onDeleteConnSuccess={getAllConnections}
                selectedConnectionInfo={connectionDataForModals as any}
            />
           <ShareWorkflowModal
           onlyShare={newWorkFlow}
            />
        </div>
    );
};