import React from 'react';
import { Modal } from '../../../components/modals';
import styles from '../styles.module.scss';

interface ViewRequestDataModalProps {
    isOpen: boolean;
    data: string[];
    onClose: () => void;
}

export const ViewRequestDataModal: React.FC<ViewRequestDataModalProps> = ({ isOpen, data, onClose }) => {
    return (
        <Modal
            isOpen={isOpen}
            toggleClose={onClose}
            title="Data Logs"
            subtitle=""
            className="errorLogsModal__container"
            showCloseMark
        >
            <div className="log_wrapper">
                <pre
                    className="output__text"
                > 
                {data.map((item, index) => (
                    <code key={index}>
                        {Array.isArray(item) ? item.join(", ") : item}
                    </code>
                ))}
                </pre>
            </div>
            <div className={styles["modalAction__btns"]}>
                <button
                    className="btn-md btn-grey-transparent"
                    type="button"
                    onClick={onClose}
                >
                    Close
                </button>

            </div>
        </Modal>
    );
};