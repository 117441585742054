import {
    CreateWorkflow, CreateWorkflowResponse, WorkflowSearchQuery, WorkflowList, DetailedWorkflowInfo,
    UpdateWorkflow,  CronSearchQueryResponse, WorkflowHistory,
    DownloadWorkflowResponse, ScheduleWorkflowData, 
    CreateProfile, GetProfilesResponse, GetComponentOutputResponse, UpdateWorkflowResponse, RunWorkflowData, CaptureSchemaResponse, PreviewStatusResponse, CloneWorkflowData, SelectedProfileResponse, 
    ProfileSearchQuery, GetActiveProfile, SyncJobResponse, DirectoriesInfoResponse, CreateDirectoryResponse,  WorkflowDirectoriesQueryParams, CaptureSchemaPayload, ShareWorkflowPostData, DagPostWorkflowInfo, OpenDatabricksNotebookPayload, DownloadDirectoryData, DownloadDagWorkflowResponse,
    RepositoriesList,
    MoveDirectory,
    MoveGitDirectory
} from './types';
import { HttpClient } from '../services';
import { makeSearchParams } from '../../helpers/makeSearchParams';
import queryString from 'query-string';
import { API, Env, zepplinUrl } from '../../constants/settings';
import { JobInfo } from '../job-handler';
import HttpInvoker from '../services/httpinvoker';
import { AxiosResponse } from 'axios';
import { ApiPromise, ApiResponse } from '../data-source-handler';
import { stringifyQueryParams } from '../../helpers/utils';
import { ExecutionEnvModes } from '../../constants/enums';
import { errorHandlerFor400 } from '../services/errorhandler';

class WorkflowHandlerClass {
    getUrl() {
        return Env.databricks ? API.databricksUrl: API.url;
    }

    CreateWorkflow(data: CreateWorkflow, callback: (arg0: CreateWorkflowResponse) => void, errorCallBack: ApiResponse<AxiosResponse> = errorHandlerFor400) {
        HttpClient.Post('Create workflow', `${API.url}/v1/workflow/`, data, callback, errorCallBack);
    }

    CloneWorkflowUsingName(data: CreateWorkflow, userId ?: string, repoId ?: number) {
        const queryParams = stringifyQueryParams({
            env: data.env,
            userId,
            repoId: repoId
        })
        return HttpClient.PostPromise(`${API.url}/v2/workflow/export/clone${queryParams}`, data);
    }

    RetrieveWorkflows(searchQueryObj: WorkflowSearchQuery): Promise<AxiosResponse<WorkflowList>> {
        if(!searchQueryObj.page) searchQueryObj.page = 1; // Make sure page number is included
        const searchQuery = stringifyQueryParams(searchQueryObj);
        // HttpClient.Get('Get workflows', `${API.url}/v2/workflow/pagination${searchQuery}`, callback);
        return HttpClient.GetPromise(`${API.url}/v2/workflow/pagination${searchQuery}`);
    }

    RetrieveSparkPythonWorkflows(callback: ApiResponse<WorkflowList>) {
        return HttpClient.Get('Retrieve spark and python workflows' ,`${API.url}/v1/airflow/workflow`, callback);
    }

    GetWorkflowInfo(workflowId: number, callback: (arg0: DetailedWorkflowInfo) => void) {
        HttpClient.Get('Get workflows', `${API.url}/v1/workflow/${workflowId}`, callback);
    }

    GetWorkflowInfoPromise(workflowId: number) {
        return HttpClient.GetPromise(`${API.url}/v1/workflow/${workflowId}`);
    }

    GetWorkflowHistory(workflowId: number, callback: (arg0: WorkflowHistory[]) => void) {
        HttpClient.Get('Get workflows', `${API.url}/v1/workflow/${workflowId}/history`, callback);
    }

    GetWorkflowInfoUsingVersion(workflowId: number | string, version: number, callback: (arg0: DetailedWorkflowInfo['data']) => void) {
        HttpClient.Get('Get workflows', `${API.url}/v1/workflow/${workflowId}/history/${version}`, callback);
    }

    UpdateWorkflow(workflowId: number, data: UpdateWorkflow, callback: (arg0: UpdateWorkflowResponse) => void) {
        HttpClient.Put('Update workflow', `${API.url}/v1/workflow/${workflowId}`, data, callback);
    }

    DownloadWorkflow(env: ExecutionEnvModes, data: any, callback: (arg0: DownloadWorkflowResponse) => void) {
        const queryParams = stringifyQueryParams({ env, spark: '2.x' });
        HttpClient.Post('DownloadWorkflow', API.url + '/v2/public/generate_code' + queryParams, data, callback);
    }

    DownloadWorkflowInArray(env: ExecutionEnvModes, data: any, callback: (arg0: DownloadWorkflowResponse) => void) {
        const queryParams = stringifyQueryParams({ env, spark: '2.x', type: 'array' });
        HttpClient.Post('DownloadWorkflowInArray', API.url + '/v2/public/generate_code' + queryParams, data, callback);
    }

    RunWorkflow(env: ExecutionEnvModes, data: RunWorkflowData, callback: (arg0: JobInfo) => void, errorHandlerCallback: any) {
        HttpClient.Post('Run Workflow', `${this.getUrl()}/batches/job/${stringifyQueryParams({env, version: '2' })}`, data, callback, errorHandlerCallback);
    }
    
    GetPreviewStatus(sessionId: string, callback: (arg0: PreviewStatusResponse) => void, errorCallback? : any) {
        HttpClient.Get('Get current job preview status', ` ${this.getUrl()}/batches/job/preview_result/${sessionId}`, callback, errorCallback);
    }

    GetPreviewStatusPromise(sessionId: string): Promise<AxiosResponse<PreviewStatusResponse>> {
        return HttpClient.GetPromise(` ${this.getUrl()}/batches/job/preview_result/${sessionId}`);
    }

    ScheduleWorkflow(env: ExecutionEnvModes, data: ScheduleWorkflowData, callback: any, errorHandlerCallback?: (arg0: any) => void) {
        HttpClient.Post('DownloadWorkflow', API.url + '/v2/schedule_workflow' + stringifyQueryParams({env}), data, callback, errorHandlerCallback);
    }

    UpdateTransformationJob(id: number, env: ExecutionEnvModes, data: ScheduleWorkflowData, callback: any) {
        HttpClient.Put('Update Workflow', API.url + '/v2/analytic_flow/' + id + stringifyQueryParams({env}), data, callback);
    }


    DeleteWorkflow(ids: number[], callback: any) {
        HttpClient.Post('Delete workflow', API.url + '/v2/workflow/multiple_delete', { ids }, callback);
    }

    CloneWorkflow(data: CloneWorkflowData, callback: (arg0: CreateWorkflowResponse) => any, errorHandlerCallBack?: any) {
        HttpClient.Post('Clone workflow', `${API.url}/v2/workflow/clone`, data, callback, errorHandlerCallBack);
    }

    ListJarFiles(callback: (arg0: string[]) => void) {
        HttpClient.Get('List all jar files', API.url + '/v2/list_jar_files', callback);
    }

    ListDatabricksJarFiles(callback: (arg0: string[]) => void) {
        HttpClient.Get('List all jar files', API.databricksFilerBrowser + '/v2/getjarslist', callback);
    }

    GetCronJobsEstimatedTime(stringifiedSearchQuery: string, timeZone: string, callback: (arg0: CronSearchQueryResponse) => void) {
        // let cronExpression = `0 ${mins} ${hrs} ? * ${day} *`;
        // if (weeknumber !== '*') cronExpression = `0 ${mins} ${hrs} ? * ${day}#${weeknumber} *`;
        const stringifiedCronExpression = queryString.stringify({ cronExpression: stringifiedSearchQuery, timeZone });
        HttpClient.Get('Get Cron jobs estimated time', `${API.url}/v1/cron?${stringifiedCronExpression}`, callback);
    }

    GetSyncJobList(callback: ApiResponse<SyncJobResponse>) {
        HttpClient.Get('Get Sync job list', `${API.url}/v2/sync_job_list`, callback);
    }

    GetComponentOutput(sessionId: string, componentId: string, callback: (arg0: GetComponentOutputResponse) => any, errorCallback?: any) {
        HttpClient.Get('Get component\'s output using session and component ID', `${this.getUrl()}/batches/job/preview_result/${sessionId}/${componentId}`, callback, errorCallback);
    }

    GetComponentOutputPromise(sessionId: string, componentId: string): ApiPromise<GetComponentOutputResponse> {
        return HttpInvoker._get(`${this.getUrl()}/batches/job/preview_result/${sessionId}/${componentId}`);
    }

    CreateProfile(data: CreateProfile, callback: any) {
        HttpClient.Post('Create profile', API.url + '/v1/builds/', data, callback);
    }

    GetProfiles(searchQueryObj: ProfileSearchQuery, callback: (arg0: GetProfilesResponse) => any) {
        const searchQuery = makeSearchParams(searchQueryObj);
        HttpClient.Get('List user profiles', `${API.url}/v1/builds/pagination${searchQuery}`, callback);
    }

    UpdateProfile(profile_id: number, data: CreateProfile, callback: any) {
        HttpClient.Put('Update profile', `${API.url}/v1/builds/${profile_id}/`, data, callback);
    }

    CloneProfiles(ids: number[], callback: any) {
        HttpClient.Post('Duplicate profiles', `${API.url}/v1/builds/multiple_clone`, { ids }, callback);
    }

    DeleteProfiles(ids: number[], callback: any) {
        HttpClient.Post('Update profile', `${API.url}/v1/builds/multiple_delete/`, { ids }, callback);
    }

    SetUserProfile(profile_id: number, callback: (arg0: SelectedProfileResponse) => any) {
        HttpClient.Post('Set default profile', `${API.url}/v1/default-build/`, { 'id': profile_id }, callback );
    }

    GetSelectedProfile(callback: (arg0: GetActiveProfile) => any, errorHandlerCallback?: (arg0: any) => void) {
        HttpClient.Get('Set default profile', `${API.url}/v1/default-build/`, callback,errorHandlerCallback);
    }

    CaptureSchema(payload: CaptureSchemaPayload, callback: (arg0: CaptureSchemaResponse[]) => any) {
        HttpClient.Post('Capture schema of a id', `${this.getUrl()}/batches/schema_capture?version=2`, { 'sparkVersion': '2.x', ...payload }, callback);
    }

    GetCaptureSchemaData(sessionId: string, callback: (arg0: CaptureSchemaResponse[]) => any) {
        HttpClient.Get('Get capture schema data', `${this.getUrl()}/batches/schema_capture/${sessionId}/dataframe`, callback);
    }

    GetCaptureSchemaLogs(sessionId: string, callback: (arg0: string[]) => any) {
        HttpClient.Get('Get capture schema data', `${this.getUrl()}/batches/schema_capture/${sessionId}/log`, callback);
    }

    CancelCaptureSchema(sessionId: string, callback: any) {
        HttpClient.Delete('Capture schema of a id', `${this.getUrl()}/batches/schema_capture/${sessionId}/terminate?version=2`, callback);
    }

    StarWorkflows(ids: number[]): Promise<AxiosResponse<any>> {
        return HttpClient.PostPromise(`${API.url}/v2/workflow/starred`, {ids});
    }

    SageMaker(workflow_name: string,data: any, callback: (arg0: DownloadWorkflowResponse) => void) {
        HttpClient.Post('SageMaker', `${API.url}/v2/public/generate_aws_sagemaker_notebook?spark=2.x&generate_type=py_spark&workflow_name=${workflow_name}`, data, callback);
    }

    OpenDatabricksNotebook(data: OpenDatabricksNotebookPayload, callback: (arg0: DownloadWorkflowResponse) => void) {
        HttpClient.Post('DataBricks', `${API.databricksUrl}/notebook/open`, data, callback);
    }

    CreateZeppelinCode(name: string, callback: (arg0: any) => void) {
        HttpClient.Post('CreateZeppelin', zepplinUrl + '/api/notebook', {
            name,
            'paragraphs': []
        }, callback, undefined, true );
    }

    UpdateNotebookForWorkflow(workflowid: string, notebookId: string, callback: (arg0: any) => void) {
        HttpClient.Put('UpdateWorkflowZeppelin', `${API.url}/zeppelin/notebook/${notebookId}/${workflowid}`, {}, callback);
    }

    GetWorkflowDirectories(userId: string, queryParams: WorkflowDirectoriesQueryParams, callback: ApiResponse<DirectoriesInfoResponse>) {
        HttpClient.Get('Get Workflow Projects', `${API.url}/v2/project_directory_by_user/${userId}${stringifyQueryParams(queryParams)}` , callback);
    }

    CreateDirectory(workflowIds: number[], env: ExecutionEnvModes, callback: ApiResponse<CreateDirectoryResponse>){
        HttpClient.Post('Create Project with workflow', `${API.url}/v2/project_directory`, { projectIds: workflowIds, env }, callback);
    }

    RenameProject(directoryId: number, name: string, callback: ApiResponse<any>) {
        HttpClient.Put('Rename Directory', `${API.url}/v2/rename_directory/${directoryId}`, { name }, callback);
    }

    MoveWorkflowsToDirectory(directoryId: number, projectIds: number[], callback: ApiResponse<any>) {
        HttpClient.Post('Move Workflows To Directory', `${API.url}/v2/move_projects`, { directoryId, projectIds }, callback);
    }

    DeleteDirectory(directoryId: number, moveProjectsToDefaultDirectory: boolean, callback: ApiResponse<any>) {
        const queryParams = queryString.stringify({ moveProjectsToDefaultDirectory });
        HttpClient.Delete('Delete Project', `${API.url}/v2/project_directory/${directoryId}?${queryParams}`, callback);
    }

    ShareWorkflow(data: ShareWorkflowPostData, callback: ApiResponse<any>, errorCallback: ApiResponse<AxiosResponse>) {
        HttpClient.Post('Share workflow', `${API.url}/v2/workflow/share`, data, callback, errorCallback);
    }

    DownloadDagWorkflow(data: DagPostWorkflowInfo, callback: ApiResponse<DownloadDagWorkflowResponse>) {
        HttpClient.Post('Download Dag Workflow', `${API.airflowUrl}/dags/download`, data, callback);
    }

    ScheduleDagWorkflow(data: DagPostWorkflowInfo, callback: ApiResponse<any>, errorCallback: ApiResponse<AxiosResponse>) {
        HttpClient.Post('Download Dag Workflow', `${API.airflowUrl}/dags/create`, data, callback,errorCallback);
    }

    DownloadDirectory(data: DownloadDirectoryData) {
        return HttpClient.GetBlob(`${API.url}/directory/download?directoryId=${data.directoryId}&env=${data.env}`);
    }

    UploadDirectory(directoryName: string, env: ExecutionEnvModes,form: FormData, repoId?: any) {
        const baseUrl = `${API.url}/directory/upload?directoryName=${directoryName}&env=${env}`;
        const url = repoId ? `${baseUrl}&repoId=${repoId}` : baseUrl;
        return HttpClient.PostFile(url, form);
    }

    GetRepositories(callback: ApiResponse<RepositoriesList[]>, errorCallback: ApiResponse<AxiosResponse>) {
        HttpClient.Get('Get Git Repos', `${API.gitUrl}/api/repo/`, callback,errorCallback);
    };

    GetListDirectories(userId: string,queryParams: any, callback: ApiResponse<DirectoriesInfoResponse>, errorCallback: ApiResponse<AxiosResponse>) {
        HttpClient.Get('Get List Directories', `${API.url}/v2/project_directory_by_user/${userId}${stringifyQueryParams(queryParams)}` , callback, errorCallback);
    }

    CreateGitDirectory(data: any, callback: (arg0: any) => void, errorCallback: ApiResponse<AxiosResponse>) {
        HttpClient.Post('Create directory', `${API.url}/v2/project_directory`, data, callback, errorCallback);
    }

    PushDirectory(data: any, callback: (arg0: any) => void, errorCallback: ApiResponse<AxiosResponse>) {
        HttpClient.Post('Push directory', `${API.gitUrl}/api/repo/sync`, data, callback, errorCallback);
    }

    HandleConflict(data: any, callback: ApiResponse<any>, errorCallback: ApiResponse<AxiosResponse>) {
        HttpClient.Put('Handle Git Conflict', `${API.gitUrl}/api/repo/resolve-conflict`, data, callback, errorCallback);
    }

    MoveGitDirectory(repoId: number|null, directoryId: number, callback: ApiResponse<any>, errorCallback: ApiResponse<any>) {
        if(repoId){
            HttpClient.Post('Move Git directory', `${API.url}/v2/directory/clone?repoId=${repoId}&directoryId=${directoryId}`, callback, errorCallback);
        }else{
            HttpClient.Post('Move Git directory', `${API.url}/v2/directory/clone?directoryId=${directoryId}`, callback, errorCallback);
        }
    }

    MoveGitWorkflows(repoId: number|null, data: MoveGitDirectory, callback: (arg0: any) => void, errorCallback: ApiResponse<AxiosResponse>) {
        if(repoId){
            HttpClient.Post('Move Git workflows', `${API.url}/v2/workflow/clone?repoId=${repoId}`, data, callback, errorCallback);
        }else{
            HttpClient.Post('Move Git workflows', `${API.url}/v2/workflow/clone`, data, callback, errorCallback);
        }
    }

    GitSync(env: string, callback: ApiResponse<any>, errorCallback: ApiResponse<AxiosResponse>) {
        HttpClient.Post('Git Sync', `${API.gitUrl}/api/git/projects/sync`, {
            env
        }, callback, errorCallback);
    }
}

const WorkflowHandler = new WorkflowHandlerClass();

export { WorkflowHandler };
