import { PreviewComponentStatusTypes, ProfileInfo, SavedWorkflowInfo, DirectoryInfo, WorkflowSearchQuery, ZepplinWorkflowData, RepositoriesList } from '../../api/workflow-handler';
import { SerializedData } from '../../components/workflow-canvas';
import { _selectoptionType } from '../../components/form/select-field';
import { CanvasTabs, InnerWorkflowTabs } from '../../pages/workflow-page/enums';
import { BaseFieldType } from '../../components/formcreators/types';
import { DsNodeExtras, DsNodeModelOptions } from '../../components/workflow-canvas/node/ds_nodemodel';
import { LoadInitialState, RESET_STATE } from '../types';
import { ExecutionEnvModes } from '../../constants/enums';
import { JobInfo } from '../../api/job-handler';
import { WorkflowConfig } from '@services/WorkflowConfig';
import { ComponentTreeViewItemInfo, ComponentTreeView } from '@services/RiTreeParser/types';
import { ComponentDescription } from 'types/component';

export const SHOW_COMPONENTS_TREEVIEW = 'SHOW_COMPONENTS_TREEVIEW';
export const TOGGLE_COMPONENTS_TREEVIEW = 'TOGGLE_COMPONENTS_TREEVIEW';
export const SHOW_PROPERTIES = 'SHOW_PROPERTIES';
export const HIDE_PROPERTIES = 'HIDE_PROPERTIES';
export const RETRIEVE_WORKFLOWS = 'RETRIEVE_WORKFLOWS';
export const CHANGE_WORKFLOWS_SELECTION = 'CHANGE_WORKFLOWS_SELECTION';
export const SET_ACTIVE_SELECTION_TYPE = 'SET_ACTIVE_SELECTION_TYPE';
export const SET_ACTIVE_UPDATECOMPONENTPARAMETER = 'SET_ACTIVE_UPDATECOMPONENTPARAMETER';
export const SELECT_SINGLE_WORKFLOW = 'SELECT_SINGLE_WORKFLOW';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const LIST_JAR_FILES = 'LIST_JAR_FILES';
export const SET_ACTIVE_TAB_IN_WORKFLOW_PAGE = 'SET_ACTIVE_TAB_IN_WORKFLOW_PAGE';
export const SET_ACTIVE_PROFILE = 'SET_ACTIVE_PROFILE';
export const SET_ACTIVE_CLUSTER = 'SET_ACTIVE_CLUSTER';
export const SET_CUSTOMCOMPONENTNAME = 'SET_CUSTOMCOMPONENTNAME';
export const SET_COMPONENTS_PARAMETERS = 'SET_COMPONENTS_PARAMETERS';
export const SET_COMPONENT_ID_FOR_RUN_TILL_SHOW = 'SET_COMPONENT_ID_FOR_RUN_TILL_SHOW';
export const SET_ACTIVE_COMPONENT_INFO = 'SET_ACTIVE_COMPONENT_INFO';
export const SET_ACTIVE_COMPONENT_PROPERTIES_STATE = 'SET_ACTIVE_COMPONENT_PROPERTIES_STATE';
export const SET_ADDITIONAL_FORM_DATA = 'SET_ADDITIONAL_FORM_DATA';
export const SET_COPIED_COMPONENT_INFO = 'SET_COPIED_COMPONENT_INFO';
export const SET_WORKFLOW_PARAMS = 'SET_WORKFLOW_PARAMS';
export const SET_CAPTURE_SCHEMA_LOGS = 'SET_CAPTURE_SCHEMA_LOGS';
export const SET_CALLBACK_FUNCTION = 'SET_CALLBACK_FUNCTION';
export const REFRESH_COMPONENT_FORM_DATA = 'REFRESH_COMPONENT_FORM_DATA';
export const SET_MODAL_TYPE_IN_WORKFLOWS_PAGE = 'SET_MODAL_TYPE_IN_WORKFLOWS_PAGE';
export const SET_VARIABLE_COMPONENT_INFO = 'SET_VARIABLE_COMPONENT_INFO';
export const TOGGLE_CONSOLE_HEIGHT = 'TOGGLE_CONSOLE_HEIGHT';
export const SET_CUSTOM_COMPONENTS_LIST = 'SET_CUSTOM_COMPONENTS_LIST';
export const SET_COMPONENT_FN_NAMES_INFO = 'SET_COMPONENT_FN_NAMES_INFO';
export const SET_CUSTOM_COMPONENT_FN_NAMES_COUNT = 'SET_CUSTOM_COMPONENT_FN_NAMES_COUNT';
export const SET_CUSTOMCOMPOENENTUPDATEID = 'SET_CUSTOMCOMPOENENTUPDATEID';
export const SET_CUSTOMCOMPONENt_MODALTYPE = 'SET_CUSTOMCOMPONENt_MODALTYPE';
export const TOGGLE_WORKFLOW_CANVAS_SPINNER = 'TOGGLE_WORKFLOW_CANVAS_SPINNER';
export const SET_JOBS_LIST = 'SET_JOBS_LIST';

export const SET_ACTIVE_UPDATE_MODALTYPE = 'SET_ACTIVE_UPDATE_MODALTYPE';
export const SET_WORKFLOW_TAB_SELECTION = 'SET_WORKFLOW_TAB_SELECTION';
export const SET_COMPONENT_TREE_DATA = 'SET_COMPONENT_TREE_DATA';
export const FETCH_GITREPOSITORIES_REQUEST = 'FETCH_GITREPOSITORIES_REQUEST';
export const FETCH_GITREPOSITORIES_SUCCESS = 'FETCH_GITREPOSITORIES_SUCCESS';
export const FETCH_GITREPOSITORIES_FAILURE = 'FETCH_GITREPOSITORIES_FAILURE';
export type RetrieveWorkflowsPayload = { totalCount: number; results: (SavedWorkflowInfo & { isLoading?: boolean; page: number })[] }
export type WorkflowInfo = { 
    name: string; 
    id: number; 
    version: number; 
    details: SerializedData | {}; 
    saved: boolean; 
    createdBy: string; 
    isStarred: boolean; 
    notes: string;
    isReadOnly: boolean; 
    isExecutable: boolean; 
    hasVariables: boolean; 
    componentCounter: number; 
    zeppelinNotebookId?: SavedWorkflowInfo['zeppelinNotebookId'];
    zepplinData: ZepplinWorkflowData[];
    directoryId: number;
	workflowPositionInList: number;
    env: ExecutionEnvModes;
    isPreviewSessionCreationInProgress: boolean;
    created: string;
    updated: string;
	config: WorkflowConfig;
}
export type SelectionType = 'single' | 'multiple';
export type ModalTypes = keyof WorkflowReducerState['showModal'];
export type WorkflowsSelectionStatus = { [id: number]: { status: boolean; name: string } }
export type SingleItemInfo = { name: string; id: number; version?: number; workflowCount?: number };
export type ComponentStatus = {
    [nodeId: string]: {
        status: PreviewComponentStatusTypes;
        sessionId: string;
        componentId: string;
    };
}
export type customComponentName = {
    name: string;
};
export type ActionTypes = {
    type: typeof RESET_STATE | typeof TOGGLE_COMPONENTS_TREEVIEW | typeof SHOW_COMPONENTS_TREEVIEW | typeof RETRIEVE_WORKFLOWS | typeof SHOW_PROPERTIES |
        typeof HIDE_PROPERTIES | typeof CHANGE_WORKFLOWS_SELECTION | typeof SET_ACTIVE_SELECTION_TYPE | typeof TOGGLE_MODAL | 
        typeof SELECT_SINGLE_WORKFLOW  | typeof SET_ACTIVE_TAB_IN_WORKFLOW_PAGE | typeof LIST_JAR_FILES |
        typeof SET_ACTIVE_PROFILE |typeof SET_CUSTOMCOMPONENTNAME | typeof SET_COMPONENT_ID_FOR_RUN_TILL_SHOW |
        typeof SET_ACTIVE_COMPONENT_INFO | typeof SET_ACTIVE_COMPONENT_PROPERTIES_STATE | typeof SET_ADDITIONAL_FORM_DATA | typeof SET_COPIED_COMPONENT_INFO | 
        typeof SET_WORKFLOW_PARAMS | typeof SET_CAPTURE_SCHEMA_LOGS | typeof SET_COMPONENTS_PARAMETERS | typeof SET_CALLBACK_FUNCTION |
        typeof SET_ACTIVE_CLUSTER | typeof REFRESH_COMPONENT_FORM_DATA | typeof SET_MODAL_TYPE_IN_WORKFLOWS_PAGE |
        typeof SET_CUSTOM_COMPONENT_FN_NAMES_COUNT | typeof TOGGLE_WORKFLOW_CANVAS_SPINNER | typeof SET_WORKFLOW_TAB_SELECTION |
        typeof SET_VARIABLE_COMPONENT_INFO | typeof TOGGLE_CONSOLE_HEIGHT | typeof SET_CUSTOM_COMPONENTS_LIST | typeof SET_COMPONENT_FN_NAMES_INFO | 
        typeof SET_ACTIVE_UPDATECOMPONENTPARAMETER | typeof SET_CUSTOMCOMPOENENTUPDATEID | typeof SET_CUSTOMCOMPONENt_MODALTYPE | typeof SET_ACTIVE_UPDATE_MODALTYPE | typeof SET_JOBS_LIST | 
        typeof SET_COMPONENT_TREE_DATA |  typeof FETCH_GITREPOSITORIES_FAILURE | typeof FETCH_GITREPOSITORIES_REQUEST | typeof FETCH_GITREPOSITORIES_SUCCESS
    payload?: any;
} | LoadInitialState

export interface ActiveComponentInfo {
    id: string;
    name: string;
    formDetails: BaseFieldType[];
    componentInfo: ComponentDescription;
    portManagementDetails?: DsNodeExtras['portManagement'];
    extraData?: any;
    type: DsNodeModelOptions['nodeType'];
    isVariableSourcePortConnected: boolean;
}

export type componentToBeCopiedInfo = {
    title: string;
    subtitle: string;
    actualTitle: string;
    iconKey: string;
    inputPorts: number;
    outputPorts: number;
    details: DsNodeExtras;
    isPropertiesTouched: boolean;
    isPropertiesValid: boolean;
    isPropertiesSavedAtleastOnce: boolean;
    nodeType: DsNodeModelOptions['nodeType'];
    env: ExecutionEnvModes;
    hash: DsNodeModelOptions['hash'];
    notes: string;
    customComponentId: number | undefined;
}

export type componentParamenters = {
    functionType: string;
    functionString: string;
    extraFn: string;
    componentDesc: string;
    functionName: string;
    inputPorts: number;
    outputPorts: number;
    noOfArgs: number;
    argList: any;
    importCode: string;
    outputList: string[];
    inputList: string[];
    name: string;
}

export type WorkflowsListParams = { page: number; workflowSearch: string }

export type ComponentTreeDataType = {
    data: ComponentTreeView;
    loading: boolean
}

export type WorkflowReducerState = {
    // directories: DirectoryInfo[];
    workflows: RetrieveWorkflowsPayload;
    workflowTabSelection: {
        selectedDirectory: null | Pick<DirectoryInfo, 'id' | 'name'>;
        activeInnerTab: InnerWorkflowTabs;
        workflowsPageNum: number;
        starredPageNum: number;
        sharredPageNum: number;
        activeSortState: {
            workflows: NonNullable<WorkflowSearchQuery['sort']>;
            directories: NonNullable<WorkflowSearchQuery['sort']>;
        };
    };
    isComponentTreeviewMinimized: boolean;
    updateComponentParameter: boolean;
    isPropertiesMinimized: boolean;
    customComponentModelTypeUpdate: boolean;
    customComponentUpdateId: number;
    workflowsSelectedState: WorkflowsSelectionStatus;
    selectionType: SelectionType;
    editorCode: string;
    customComponentName: customComponentName;
    componentParamenters: componentParamenters;
    showModal: {
        newWorkflow: boolean;
        runWorkflow: boolean;
        renameWorkflow: boolean;
        shareWorkflow: boolean;
        deleteWorkflow: boolean;
        scheduleWorkflow: boolean;
        loseUnsavedChanges: boolean;
        managePorts: boolean;
        newComponent: boolean;
        customEditor: boolean;
        customComoponentParameters: boolean;
        profile: boolean;
        loseUnsavedFormChanges: boolean;
        loseUnsavedComponentNotesChanges: boolean
        renameComponent: boolean;
        componentImport: boolean;
        deleteComponent: boolean;
        createFolderDbfs: boolean;
        fileUploadDbfs: boolean;
        userPermission: boolean;
        fileUploadAzureBlobStorage: boolean;
        mlBuildPipelineModal: boolean;
        streamingPreviewWorkflow: boolean;
    };
    singleItemInfoForModals: SingleItemInfo;
    listjarfiles: _selectoptionType[];
    activeTab: CanvasTabs;
    activeProfile: ProfileInfo | null;
    activeCluster: { id: string; name: string } | null;
    idOfComponentForRunTillHere: string;
    activeComponentInfo: ActiveComponentInfo | null;
    activeComponentPropertiesInfo: {isSaved: boolean; show: boolean; isValid: boolean, isNotesSaved: boolean};
    additionalValuesForForm: any;
    componentToBeCopied: componentToBeCopiedInfo | null;
    workflowsParams: WorkflowsListParams;
    captureSchemaLogs: string[];
    callbackFunc?: (arg0?: any) => any;
    // id is included in object to trigger function update on each change, not possible with string
    componentToBeRefreshed: {id: string} | null;
    modalType: 'workflow' | 'profile' | 'canvasItems' | 'directory';
    variableComponent: ComponentTreeViewItemInfo | null;
    toggleConsoleHeight: boolean;
    customComponentsList: any[];
    customComponentFnNamesCount: number;
    componentFnNamesInfo: Record<string, string>;
    customComponentModelType: 'new' | 'update';  
    showWorkflowCanvasSpinner: boolean;        
    jobsList: JobInfo[];
    componentTreeData: ComponentTreeDataType;
    isGitLoading: boolean;
    gitRepositories: any;
    errorGitRepo: string;
}