import { HttpClient } from '../services';
import {  ConnectionInfo, CreateConnection, AllConnectionsInfo, GetTablesInfo, RecordsSearchQuery, 
    RecordsInfoResponse, CreateCatalogType, CatalogInfo, ApiPromise, ApiResponse, PiRequestQueryResponse, PiRequestQuery, NewConnectionMetadata, NiFiManagedNodesResponse, AwsConnectionType, AuditRequestHistoryType, queryRequest, queryResponse, DeepSqlConnectionInfo } from './types';
import { API, Env } from '../../constants/settings';
import { makeSearchParams } from '../../helpers/makeSearchParams';
import queryString from 'query-string';
import { DeleteCustomComponentResponse } from '../new-custom-component';
import { WitsmlHandler } from './witsml-handler';
import { NiFiSinkData } from '@pages/admin/nifi_management/types';
import isEmpty from 'lodash/isEmpty'
import { AI_QUERY, AI_RESPONSE, AI_QUERY_LANGGRAPH } from '@pages/data-explorer/gen-ai/types';
import { AxiosPromise, AxiosResponse } from 'axios';

class DataSourceHandlerClass extends WitsmlHandler {
    CreateConnection(data: CreateConnection, callback: (arg0: ConnectionInfo[]) => any, errorHandlerCallback: any){
        HttpClient.Post('Create a connection', API.databrowserUrl + '/v2/connection', data, callback, errorHandlerCallback);
    }

    UpdateConnection(connectionId: string, data: CreateConnection, callback: (arg0: ConnectionInfo[]) => any, errorHandlerCallback: any){
        HttpClient.Put('Create a connection', API.databrowserUrl + `/v2/connection/${connectionId}`, data, callback, errorHandlerCallback);
    }

    DeleteConnection(connectionId: number, callback: (arg0: DeleteCustomComponentResponse) => any, errorHandlerCallback: any){
        HttpClient.Delete('Create a connection', API.databrowserUrl + `/v2/connection/${connectionId}`, callback, errorHandlerCallback);
    }

    GetAllConnectionsInfo(callback: (arg0: AllConnectionsInfo) => any) {
        HttpClient.Get('Get all connections', API.databrowserUrl + '/v2/connection-metadata', callback);
    }

    GetDatabaseTypeList(callback: (arg0: NewConnectionMetadata) => any) {
        HttpClient.Get('Get Database Type List', API.databrowserUrl + '/v2/connection-type-metadata', callback);
    }

    GetTables(data: GetTablesInfo, callback: (arg0: string[]) => any,errorHandlerCallback: any) {
        const searchQuery = makeSearchParams(data);
        HttpClient.Get('Get Database Type List', API.databrowserUrl + `/v2/tables${searchQuery}`, callback,errorHandlerCallback);
    }

    GetRecords(searchQuery: RecordsSearchQuery, callback: (arg0: RecordsInfoResponse) => any){
        const _searchQuery = queryString.stringify(searchQuery, { encode: true });
        HttpClient.Get('Get Database Type List', API.databrowserUrl + `/v2/records?${_searchQuery}`, callback);
    }

    GetDatabasesOfAConnection(connectionId: number, callback: (arg0: string[]) => void){
        HttpClient.Get('Get all connections', API.databrowserUrl + `/v2/databases?connectionId=${connectionId}`, callback);
    }

    CreateCatalog(data: CreateCatalogType): ApiPromise<CatalogInfo> {
        return HttpClient.PostPromise( API.databrowserUrl + '/v2/catalogs', data);
    }
    
    GetCatalogs(callback: (arg0: any) => any) {
        HttpClient.Get('Get all connections', API.databrowserUrl + '/v2/catalogs', callback);
    }

    GetPIServer(connectionId: number, callback: (arg0: any) => void, error: (arg0: any) => void ) {
        HttpClient.Post('Get PI Server List', API.databrowserUrl + `/v2/osi-servers?connectionId=${connectionId}`, {
            'attr_type' : 'PI_Server',
            'site_id': 2
        }, callback, error);
    }

    GetPIData(connectionId: number, data: any, callback: (arg0: any) => void, error: (arg0: any) => void){
        HttpClient.Post('Get PI Data', API.databrowserUrl + `/v2/osi-data?connectionId=${connectionId}`, data, callback, error);
    }

    GetContainersListInAzureBlobConn(connectionId: number, callback: ApiResponse<string[]>) {
        HttpClient.Get('Get PI Data', API.databrowserUrl + `/v2/azureblob/listofcontainers?connectionId=${connectionId}`, callback);
    }

    GetAzureBlobsOfContainer(connectionId: number, containerName: string, callback: ApiResponse<string[]>) {
        HttpClient.Get('Get PI Data', API.databrowserUrl + `/v2/azureblob/listofblobs?connectionId=${connectionId}&containerName=${containerName}`, callback);
    }

    GetEdgeNodes(callback: ApiResponse<any>) {
        HttpClient.Get('GetEdgeNodes', API.databrowserUrl + '/v2/node', callback)
    }

    GetSinkNodes(callback: ApiResponse<any>) {
        HttpClient.Get('GetSinkNodes', API.databrowserUrl + '/v2/sink/', callback)
    }

    GetNodesOfNiFiManaged(callback: ApiResponse<NiFiManagedNodesResponse>) {
        HttpClient.Get('GetNodesOfNiFiManaged', API.databrowserUrl + '/v2/node-metadata', callback)
    }

    GetRequestsOfPiNode(nodeId: string, searchQuery: PiRequestQuery, callback: PiRequestQueryResponse) {
        const _searchQuery = queryString.stringify(searchQuery, { encode: true, skipNull: true });
        HttpClient.Get('GetRequestsOfPiNode', API.databrowserUrl + `/v2/request/pagination/${nodeId}?${_searchQuery}`, callback)
    }

    CreatePiNodeRequest(nodeName: string, requestName: string, stringifiedJson: string, sinkName:string, sinkKind:string, callback: ApiResponse<any>) {
        HttpClient.Post('Create Node Request', API.databrowserUrl + '/v2/request', { nodeName, json: stringifiedJson, state: "new", requestName , sinkName, sinkKind}, callback)
    }

    SubmitPiRequest(requestId: number, callback: ApiResponse<any>,errorHandlerCallback?: any) {
        HttpClient.Put('Submit Node Pi Request', API.databrowserUrl + '/v2/request/' + requestId + '/submit', {}, callback,errorHandlerCallback)
    }

    StopPiRequest(requestId: number, callback: ApiResponse<any>) {
        HttpClient.Put('Submit Node Pi Request', API.databrowserUrl + '/v2/request/' + requestId + '/stop', {}, callback)
    }

    DeletePiRequest(requestId: number, callback: ApiResponse<any>) {
        HttpClient.Delete('Delete Node Pi Request', API.databrowserUrl + '/v2/request/' + requestId, callback)
    }

    DownloadPiRequestJson(requestId: number, callback: ApiResponse<any>) {
        HttpClient.Get('Submit Node Pi Request', API.databrowserUrl + '/v2/request/' + requestId + '/download', callback)
    }

    UpdatePiRequestJson(requestId: string, requestName: string, stringifiedJson: string, sinkName:string, sinkKind:string, callback: ApiResponse<any>) {
        HttpClient.Put('Update Node Pi Request Json', API.databrowserUrl + '/v2/request/' + requestId + '/json', { json: stringifiedJson, requestName, sinkName, sinkKind }, callback)
    }

    ClonePiRequest(requestId: number, callback: ApiResponse<any>) {
        HttpClient.Put('Submit Node Pi Request', API.databrowserUrl + '/v2/request/' + requestId + '/clone', {}, callback)
    }

    DeleteEdgeByID(requestId: number, callback: ApiResponse<any>) {
        HttpClient.Delete('Delete Edge Request', API.databrowserUrl + '/v2/node/' + requestId, callback)
    }

    CreateEdgeNode(data: any, callback: ApiResponse<any>, errorHandlerCallback: any) {
        HttpClient.Post('Create a Edge Connection', API.databrowserUrl + '/v2/node', data, callback, errorHandlerCallback);
    }
    
    UpdateEdgeNode(nodeName: string, data: any, callback: ApiResponse<any>, errorHandlerCallback: any) {
        HttpClient.Put('Update Edge Connection', API.databrowserUrl + '/v2/node/' + nodeName , data, callback, errorHandlerCallback);
    }

    DeleteEdgeNode(nodeName: string, callback: ApiResponse<any>, errorHandlerCallback: any) {
        HttpClient.Delete('Create a Edge Connection', API.databrowserUrl + '/v2/node/' + nodeName, callback, errorHandlerCallback);
    }

    CreateSinkNode(data: NiFiSinkData, callback: ApiResponse<any>, errorHandlerCallback: any) {
        HttpClient.Post('Create a Sink Connection', API.databrowserUrl + '/v2/sink/', data, callback, errorHandlerCallback);
    }

    UpdateSinkNode(sinkName: string, data: NiFiSinkData, callback: ApiResponse<any>, errorHandlerCallback: any) {
        HttpClient.Put('Update Sink Connection', API.databrowserUrl + '/v2/sink/' + sinkName , data, callback, errorHandlerCallback);
    }

    DeleteSinkNode(sinkName: string, callback: ApiResponse<any>, errorHandlerCallback: any) {
        HttpClient.Delete('Delete a Sink Connection', API.databrowserUrl + '/v2/sink/' + sinkName, callback, errorHandlerCallback);
    }

    DownloadEdgeCertificate(nodeName:  any) {
        return HttpClient.GetBlob(API.databrowserUrl + `/v2/${nodeName}/generate-config`);
    }
    GetNodeNames(type: string, callback: ApiResponse<any>) {
        HttpClient.Get('Submit Node Pi Request', API.databrowserUrl + '/v2/node-name?nodeKind=' + type, callback);
    }

    GetSinkType(callback: ApiResponse<any>) {
        HttpClient.Get('Submit Node Pi Request', API.databrowserUrl + '/v2/sink-type', callback);
    }

    GetConnectionBySinkKind(sinkKInd: string, callback: (arg0: string[]) => void){
        HttpClient.Get('Get all connections by sinkKind', API.databrowserUrl + `/v2/connections?database_type=${sinkKInd}`, callback);
    }

    GetNodeKind(callback: ApiResponse<any>) {
        HttpClient.Get('Submit Node Pi Request', API.databrowserUrl + '/v2/node-kind', callback);
    }

    CloneConnectionRequest(connectionId: number, callback: ApiResponse<string>) {
        HttpClient.Get('Submit Clone Connection Request', API.databrowserUrl + '/v2/connection/clone?connectionId='+ connectionId, callback);
    }

    GetAWSConnectionList(callback: ApiResponse<AwsConnectionType[]>) {
        HttpClient.Get('Get AWS Connection List Request', API.databrowserUrl + '/v1/datasource?fs_type=s3', callback);
    }
    
    GetAuditReqHistory(requestId:string,callback: ApiResponse<AuditRequestHistoryType[]>) {
        HttpClient.Get('Get Audit Request History', API.databrowserUrl + '/v2/audit/request/'+ requestId, callback);
    }

    GetProgressReqHistory(requestId:string,callback: ApiResponse<any[]>) {
        HttpClient.Get('Get Process Request History', API.databrowserUrl + `/v2/request/${requestId}/progress`, callback);
    }
    
    SaveQueryResponse(data: queryRequest, callback?:any, errorHandlerCallback?: any) {
        HttpClient.Post('Save Query Response', API.databrowserUrl + '/v2/query', data, callback, errorHandlerCallback);
    }

    GetAllQueryResponse(page:number,callback: ApiResponse<queryResponse>) {
        HttpClient.Get('Get All Query Response', API.databrowserUrl + '/v2/query?page='+ page+'&size=5', callback);
    }

    GetDatabaseTableWithSchema(connectionId: number) {
        return HttpClient.GetPromise(API.databrowserUrl + `/v2/database-meta-data/${connectionId}`);
    }

    GetTablesPromise(data: GetTablesInfo) {
        const searchQuery = makeSearchParams(data);
        return HttpClient.GetPromise(API.databrowserUrl + `/v2/tables${searchQuery}`);
    }

    RefreshConnectionId(connectionId: number) {
        return HttpClient.GetPromise(API.databrowserUrl + `/v2/database-meta-data/${connectionId}/refresh`);
    }

    OpenAIGPT(connectionId: number, prompt: string) {
        return HttpClient.PostPromise(API.databrowserUrl + `/v2/openai/query/${connectionId}`, {
            prompt
        })
    }
    AzureGPT(connectionId: number, prompt: string, settings: any) {
        return HttpClient.PostPromise(API.databrowserUrl + `/v2/openai/query/${connectionId}?cognitiveServices=AzureOpenAI`, {
            prompt,
            ...settings
        })
    }
    GenerativeAI(connectionId: number, prompt: string, settings: any) {
        return HttpClient.PostPromise(API.databrowserUrl + `/v2/openai/query/${connectionId}?cognitiveServices=AzureOpenAI&checkQuery=true`, {
            prompt,
            ...settings
        })
    }
    GenerativeAINew(connectionId: number,prompt: string, settings?: any) {
        return HttpClient.PostPromise(API.deepSqlUrl + `/schema/${connectionId}/query_gpt_sql`, {
            query:prompt,
            ...settings
        })
    }

    GenerativeAICygnet(connectionId: number,prompt: string) {
        return HttpClient.PostPromise(Env.cygnetDemoURL + `/schema/${connectionId}/query_cygnet?ss_wford=true`, {
            query:prompt,
        })
    }
    OpenAI(prompt: string, settings: any) {
        return HttpClient.PostPromise(API.databrowserUrl + `/v2/openai/azure/query`, {
            prompt,
            ...settings
        })
    }
    DeepSQLChat(db_id: number, session_id: string, data: AI_QUERY,username:string): AxiosPromise<AI_RESPONSE> {
        if(!isEmpty(session_id)) {
            return HttpClient.PostPromise(API.deepSqlUrl + `/v2/schema/${db_id}/gpt/?session_id=${session_id}&username=${username}`, data)
        }
        return HttpClient.PostPromise(API.deepSqlUrl + `/v2/schema/${db_id}/gpt/?username=${username}`, data)
    }

    DeepSQLChatLanggraph(db_id: number, session_id: string, data: AI_QUERY_LANGGRAPH,username:string): AxiosPromise<AI_RESPONSE> {
        if(!isEmpty(session_id)) {
            return HttpClient.PostPromise(API.deepSqlUrl + `/v2/langgraph/${db_id}/?session_id=${session_id}&username=${username}`, data)
        }
        return HttpClient.PostPromise(API.deepSqlUrl + `/v2/langgraph/${db_id}/?username=${username}`, data)
    }

    CygnetSQLChat(session_id: string, data: AI_QUERY): AxiosPromise<AI_RESPONSE> {
        if(!isEmpty(session_id)) {
            return HttpClient.PostPromise(`https://deepsqldeploy5.dev.deepiqlab.com/v2/schema/cygnet_custom/gpt/?session_id=${session_id}`, data)
        }
        return HttpClient.PostPromise(`https://deepsqldeploy5.dev.deepiqlab.com/v2/schema/cygnet_custom/gpt/`, data)
    }

    GetLogs(query_uuid: string) {
        return HttpClient.GetPromise(API.deepSqlUrl + `/v2/query_logs/?query_uuid=${query_uuid}`)
    }

    GetSchemaForiegn(db_id: number) {
        return HttpClient.GetPromise(API.deepSqlUrl + `/v2/${db_id}/schema_foreign_key/`)
    }

    GetSchemaTable(db_id: number) {
        return HttpClient.GetPromise(API.deepSqlUrl + `/v2/${db_id}/schema_table_aug/`)
    }

    GetSchemaColumn(db_id: number) {
        return HttpClient.GetPromise(API.deepSqlUrl + `/v2/${db_id}/schema_column_aug/`)
    }

    PostSchemaForeign(db_id: number, data: any) {
        return HttpClient.PostPromise(API.deepSqlUrl + `/v2/${db_id}/schema_foreign_key/`, data)
    }

    PostSchemaTable(db_id: number, data: any) {
        return HttpClient.PostPromise(API.deepSqlUrl + `/v2/${db_id}/schema_table_aug/`, data)
    }

    PostSchemaColumn(db_id: number, data: any) {
        return HttpClient.PostPromise(API.deepSqlUrl + `/v2/${db_id}/schema_column_aug/`, data)
    }

    DownloadLogs(session_id: string) {
        return HttpClient.GetPromise(API.deepSqlUrl + `/v2/chat_history?session_id=${session_id}`)
    }

    GetSchemaIndex(db_id: number) {
        return HttpClient.GetPromise(API.deepSqlUrl + `/schema/custom/${db_id}/indexing`)
    }

    GetVirtualViewList(db_id: number) {
        return HttpClient.GetPromise(API.deepSqlUrl + `/v2/virtual_view_list/?database_id=${db_id}`)
    }

    GetAllDeepSqlConnectionsInfo(callback: (arg0: DeepSqlConnectionInfo[]) => any,errorCallback: ApiResponse<AxiosResponse>) {
        HttpClient.Get('Get all deepsql connections', API.deepSqlUrl + '/databases/', callback,errorCallback);
    }
 
    GetDeepSqlDatabaseTableWithSchema(connectionId: number) {
        return HttpClient.GetPromise(API.deepSqlUrl + `/tables/?database_id=${connectionId}`);
    }

    PostVirtualView(data: any,callback: ApiResponse<any>, errorCallback: ApiResponse<AxiosResponse>) {
        return HttpClient.Post("Post Virtual View",API.deepSqlUrl + `/v2/virtual_view/`, data,callback,errorCallback);     
    }

    Feedback(query_uuid: string, impression: boolean, comments: string, sessionId: string ) {
        return HttpClient.PostPromise(API.deepSqlUrl + `/v2/feedback/`, {
            query_uuid,
            positive: impression,
            comments,
            session_uuid: sessionId
        })
    }

    GetNifiGraphData(nodeName: string, time: string, type: string,  callback: ApiResponse<any>) {
        HttpClient.Get('GetNifiGraphData', API.databrowserUrl + `/v2/node/status/changes/${nodeName}?type=${type}&filter=${time}`, callback)
    }

    ShowHideFeedBack(username: string, callback: ApiResponse<any>) {
        HttpClient.Get('Show or Hide Feedback', API.deepSqlUrl+`/get-user-config/?username=${username}`, callback) 
    }
    
}

const DataSourceHandler = new DataSourceHandlerClass();

export { DataSourceHandler };