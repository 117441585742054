import React, { useEffect, useMemo, useState } from "react";
import Form, { InputField, NewSelectField } from "../../../../components/form";
import * as yup from "yup";
import {
	_selectoptionType,
} from "../../../../components/form/select-field";
import styles from "../../styles.module.scss";
import { DataSourceHandler } from "@api/data-source-handler";

const nifiFormSchema = yup.object({
	sinkName: yup.string().required("This is a required field"),
	sinkKind: yup.string().required("This is a required field"),
	connectionName: yup.string().required("This is a required field"),
	objectKey: yup.string().nullable().when("sinkKind", {
		is: "S3",
		then: yup.string().required("This is a required field"),
	}),
	bucket: yup.string().nullable().when("sinkKind", {
		is: "S3",
		then: yup.string().required("This is a required field"),
	}),
	containerName: yup.string().nullable().when("sinkKind", {
		is: "AzureBlob",
		then: yup.string().required("This is a required field"),
	}),
	blob: yup.string().nullable().when("sinkKind", {
		is: "AzureBlob",
		then: yup.string().required("This is a required field"),
	}),
	amazonKinesisStreamName: yup.string().nullable().when("sinkKind", {
		is: "Kinesis",
		then: yup.string().required("This is a required field"),
	}),
	eventHubName: yup.string().nullable().when("sinkKind", {
		is: "AzureEventHub",
		then: yup.string().required("This is a required field"),
	}),
	eventHubNamespace: yup.string().nullable().when("sinkKind", {
		is: "AzureEventHub",
		then: yup.string().required("This is a required field"),
	}),
	filesystemName: yup.string().nullable().when("sinkKind", {
		is: "ADLSGen2",
		then: yup.string().required("This is a required field"),
	}),
	directoryName: yup.string().nullable().when("sinkKind", {
		is: "ADLSGen2",
		then: yup.string().required("This is a required field"),
	}),
	fileName: yup.string().nullable().when("sinkKind", {
		is: "ADLSGen2",
		then: yup.string().required("This is a required field"),
	}),
});

export type NifiFormValues = yup.InferType<typeof nifiFormSchema>;

export type UserFormProps = {
	type: "UPDATE" | "ADD";
	initialValues: any;
	submitButtons?: React.ReactNode;
	onSubmit?: (arg0: NifiFormValues) => any;
};

const sinkType = { 
	"AzureEventHub": "Azure Event Hub",
	"S3": "S3",
	"Kinesis": "Kinesis",
	"AzureBlob": "Azure Blob",
	"ADLSGen2":"ADLS Gen 2"	
}

const sinkTypeConnectionNameMapping = {
	"AzureEventHub": "EventsHub",
	"S3": "S3",
	"Kinesis": "Kinesis",
	"AzureBlob": "AzureBlobStorage",
	"ADLSGen2":"Gen2"	
}

export const NifiForm: React.FC<UserFormProps> = ({
	onSubmit,
	type,
	initialValues,
	submitButtons,
}) => {
	const [sinkTypeOptions, setSinkTypeOptions] = useState<_selectoptionType[]>(
		[]
	);

	const [connectionNameOptions, setConnectionNameOptions] = useState<_selectoptionType[]>(
		[]
	);

	const [selectedSinkType, setSelectedSinkType] = useState(initialValues?.sinkKind || "S3");
	const handleFormSubmit = (values: NifiFormValues) => {
		onSubmit && onSubmit(values);
	};

	const initialValuesForForm: NifiFormValues = useMemo(() => {
		return {
			sinkName: initialValues?.sinkName || "",
			sinkKind: initialValues?.sinkKind || "S3",
			objectKey: selectedSinkType === "S3" ? '${app-name}/${edge-name}/data/hour=${current-date}-${hour}/${minute}-${second}-${millisecond}-${request-id}' : (initialValues?.objectKey || ""),
			bucket: initialValues?.bucket || "",
			containerName: initialValues?.containerName || "",
			blob: initialValues?.blob || "",
			amazonKinesisStreamName: initialValues?.amazonKinesisStreamName || "",
			eventHubName: initialValues?.eventHubName || "",
			eventHubNamespace: initialValues?.eventHubNamespace || "",
			filesystemName:initialValues?.filesystemName || "",
			directoryName:initialValues?.directoryName || "",
			fileName:initialValues?.fileName || "",
			connectionName:initialValues?.connectionName || "",
		};
	}, [initialValues, type]);

	const handleSinkKind = (sinkKind:string)=>{
		DataSourceHandler.GetConnectionBySinkKind(sinkTypeConnectionNameMapping[sinkKind as keyof typeof sinkTypeConnectionNameMapping],(res)=>{
			setConnectionNameOptions(
				res.map((_res:any) => {
					return {
						label: _res.name,
						value: _res.name,
					};
				})
			);
		});
		setSelectedSinkType(sinkKind);
	}

	useEffect(() => {
		DataSourceHandler.GetSinkType((res) => {
			setSinkTypeOptions(
				res.sort().map((_res: any) => {
					const sinkLabel = sinkType[_res as keyof typeof sinkType]
					return {
						label: sinkLabel,
						value: _res
					};
				})
			);
		});
		handleSinkKind(initialValues?.sinkKind || "S3")
	}, []);

	return (
		<Form
			initialValues={initialValuesForForm}
			validationSchema={nifiFormSchema}
			onSubmit={handleFormSubmit}
			enableReinitialize
		>
			 {({ _formikprops: { setFieldValue } }) => (
				<>
					<div className={styles["sinkForm"]}>
						<InputField
							name="sinkName"
							label="Sink Name"
							required={true}
							autoComplete="off"
							disabled={type === "UPDATE"}
						/>
						<NewSelectField
							name="sinkKind"
							label="Choose Sink Type"
							options={sinkTypeOptions}
							onOptionClick={(option) => {
								setFieldValue("connectionName","");
								handleSinkKind(option.value);
							}}
							required={true}
							className="mb-0"
						/>
						<NewSelectField
							name="connectionName"
							label="Choose Connection"
							options={connectionNameOptions}
							required={true}
							className="mb-0"
						/>
						{selectedSinkType === "S3" && (
							<>
								<InputField
									name="objectKey"
									label="Object Key"
									required={true}
									placeholder="Add Object Key"
									autoComplete="off"
								/>
								<InputField
									name="bucket"
									label="Bucket"
									required={true}
									autoComplete="off"
								/>
							</>
						)}
						{selectedSinkType === "AzureBlob" && (
							<>
								<InputField
									name="containerName"
									label="Container Name"
									required={true}
									placeholder="Add Container Name"
									autoComplete="off"
								/>
								<InputField
									name="blob"
									label="Blob"
									required={true}
									autoComplete="off"
								/>
							</>
						)}

						{selectedSinkType === "Kinesis" && (
							<>
								<InputField
									name="amazonKinesisStreamName"
									label="Amazon Kinesis Stream Name"
									required={true}
									autoComplete="off"
								/>
							</>
						)}

						{selectedSinkType === "AzureEventHub" && (
							<>
								<InputField
									name="eventHubName"
									label="Event Hub Name"
									placeholder="Add Event Hub Name"
									required={true}
									autoComplete="off"
								/>
								<InputField
									name="eventHubNamespace"
									label="Event Hub Namespace"
									required={true}
									autoComplete="off"
								/>
							</>
						)}

						{selectedSinkType === "ADLSGen2" && (
							<>
								<InputField
									name="filesystemName"
									label="Filesystem Name"
									required={true}
									autoComplete="off"
								/>
								<InputField
									name="directoryName"
									label="Directory Name"
									required={true}
									autoComplete="off"
								/>
								<InputField
									name="fileName"
									label="File Name"
									required={true}
									autoComplete="off"
								/>
							</>
						)}
					</div>
					{!!submitButtons && submitButtons}
				</>
			 )}
		</Form>
	);
};

