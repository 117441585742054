import React, { useEffect, useRef, useState } from "react";
import { BackFolder } from "./BackFolder";
import { File, FileExplorerProps } from "./types";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { FileItem } from "./FileItem";
import { FileExplorerTable } from "./ui/FileExplorerTable";
import { useDidUpdate } from "rooks5";
import { FileContextMenu } from "./ui/FileContextMenu";
import { isMac } from "@helpers/utils";
import { FileListWrapper } from "./ui/FileListWrapper";
import { FileListLoader } from "./FileListLoader";
import { Checkbox } from "antd";
import { Flex } from "@components/ui/Flex";
import { isEmpty } from "lodash";
import { ShowWhenTrue } from "@helpers/showwhentrue";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/types";

type Props = FileExplorerProps & {
	search?: string;
	setSearchResultCount: (count: number) => void;
	setSearch: (text: string) => void;
};

export const FileList = ({
	files,
	folderChain,
	disableBackFolder,
	loading,
	contextMenu,
	search,
	setSearch,
	enableMultiSelect,
	clearSelectionOnOutsideClick,
	onFileDoubleClick,
	onFileClick,
	onFileSelect,
	setFolderChain,
	setSearchResultCount,
	selectedFiles,
	setSelectedFiles,
	view,
}: Props) => {
	const [filesToRender, setFilesToRender] = useState<File[]>([]);
	const [sort, setSort] = useState({ by: "name", asc: true });

	const fileListRef = useRef(null);
	const dispatch=useDispatch();
	// useOutsideClick(
	// 	fileListRef,
	// 	() => {
	// 		setSelectedFiles([]);
	// 	},
	// 	selectedFiles.length > 0 && clearSelectionOnOutsideClick
	// );
	const pathInfo = useSelector(
		(store: RootState) => store.AccountReducer.pathInfo
	);

	useEffect(() => {
		let sortedFiles = [...files];

		if (search) {
			const searchString = search.trim().toLowerCase();
			sortedFiles = sortedFiles.filter((file) =>
				file.name.toLowerCase().includes(searchString)
			);
			setSearchResultCount(sortedFiles.length);
		}

		sortedFiles.sort((a, b) => {
			if (a.isDir && !b.isDir) return -1;
			if (!a.isDir && b.isDir) return 1;

			let aText = (a as any)[sort.by];
			let bText = (b as any)[sort.by];

			if (typeof aText === "string") {
				aText = aText.toLowerCase();
				bText = bText.toLowerCase();
			}

			if (sort.asc) {
				if (aText < bText) return -1;
				if (aText > bText) return 1;
			} else {
				if (aText > bText) return -1;
				if (aText < bText) return 1;
			}
			return 0;
		});
		setFilesToRender(sortedFiles);
	}, [files, sort, search, pathInfo]);

	useEffect(() => {
		if (selectedFiles.length && onFileSelect) {
			onFileSelect(selectedFiles);
		}
	}, [selectedFiles]);

	useDidUpdate(() => {
		setSelectedFiles([]);
	}, [files, folderChain, pathInfo]);

	const _onFileClick = (event: React.MouseEvent, file: File) => {
		onFileClick && onFileClick(file);
	
		if (enableMultiSelect) {
			if ((isMac() && event.metaKey) || event.ctrlKey) {
				selectedFiles.includes(file)
					? setSelectedFiles(selectedFiles.filter((f) => f !== file))
					: setSelectedFiles([...selectedFiles, file]);
				return;
			}
			if (event.shiftKey && selectedFiles.length) {
				const index = filesToRender.indexOf(file);
				const lastSelectedFile =
					selectedFiles[selectedFiles.length - 1];
				const lastFileIndex = filesToRender.indexOf(lastSelectedFile);
				const updatedSelectedFiles =
					index > lastFileIndex
						? filesToRender.slice(lastFileIndex, index + 1)
						: filesToRender.slice(index, lastFileIndex + 1);
				setSelectedFiles(updatedSelectedFiles);
				return;
			}
		}
		if(!enableMultiSelect){
			setSelectedFiles([file]);
			return;
		}
		selectedFiles.includes(file)
		? setSelectedFiles(selectedFiles.filter((f) => f !== file))
		: setSelectedFiles([...selectedFiles, file]);
		// setSelectedFiles([file]);
	};

	const _onFileDoubleClick = (file: File) => {
		if (file.isDir) {
			setSelectedFiles([]);
			setFolderChain([...folderChain, file]);
			setSearch('');
			dispatch({
				type: 'PATH_INFO',
				payload: [...pathInfo, file]
			});
		} else {
			setSelectedFiles([file]);
		}
		onFileDoubleClick && onFileDoubleClick(file);
	};

	const onContextMenu = (file: File) => {
		if (!selectedFiles.includes(file)) {
			setSelectedFiles([file]);
		}
	};

	const onBackClick = () => {
		setFolderChain(folderChain.slice(0, -1));
		const updatedPathInfo = pathInfo.slice(0, -1); 
            dispatch({
                type: 'PATH_INFO',
                payload: updatedPathInfo 
            });
		return;
	};

	const onHeaderClick = (name: string) => {
		setSort({
			by: name,
			asc: sort.by === name ? !sort.asc : true,
		});
	};

	const filesToRenderWithoutDir  = filesToRender.filter((item)=>!item.isDir);
	const selectedFilesWithoutDir  = selectedFiles.filter((item)=>!item.isDir);

	const onSelectUnSelectAll =() => {
		if (filesToRenderWithoutDir.length === selectedFilesWithoutDir.length && isEmpty()) {
			setSelectedFiles([]);
		} else {
			setSelectedFiles(filesToRenderWithoutDir);
		}
	};

	return (
		<FileListWrapper view={view} ref={fileListRef}>
			<ContextMenuTrigger id="file-explorer-context-menu">
				<FileExplorerTable view={view}>
					{view === "Table" && (
						<thead style={{zIndex:-1}}>
							<tr>
								<th onClick={() => onHeaderClick("name")} style={{zIndex:1}}>
									<Flex>
										<ShowWhenTrue show={!!enableMultiSelect && !loading}>
											<Checkbox
												checked={filesToRenderWithoutDir.length === selectedFilesWithoutDir.length}
												onClick={onSelectUnSelectAll}
												indeterminate={filesToRenderWithoutDir.length >(selectedFilesWithoutDir.length) && selectedFilesWithoutDir.length !== 0}		
											/>
										</ShowWhenTrue>
										<Flex ml={2}>Name</Flex>
									</Flex>
								</th>
								<th onClick={() => onHeaderClick("type")}>
									Type
								</th>
								<th onClick={() => onHeaderClick("size")}>
									Size
								</th>
								<th onClick={() => onHeaderClick("modDate")}>
									Last Modified / Upload
								</th>
							</tr>
						</thead>
					)}
					{loading ? (
						<tbody>
							<FileListLoader view={view} />
						</tbody>
					) : (
						<tbody>
							{disableBackFolder ||
								(folderChain.length > 1 && (
									<BackFolder
										onDoubleClick={onBackClick}
										view={view}
									/>
								))}
							{filesToRender.map((file: File) => (
								<FileItem
									view={view}
									isSelected={selectedFiles.includes(file)}
									file={file}
									key={file.id}
									onClick={_onFileClick}
									onDoubleClick={_onFileDoubleClick}
									onContextMenu={onContextMenu}
									enableMultiSelect={enableMultiSelect}
								/>
							))}
						</tbody>
					)}
				</FileExplorerTable>
			</ContextMenuTrigger>
			{!!contextMenu && (
				<FileContextMenu>
					<ContextMenu
						hideOnLeave
						id="file-explorer-context-menu"
						className={selectedFiles.length ? "" : "hidden"}
					>
						{contextMenu
							.filter(
								(menu) =>
									(selectedFiles.length === 1 &&
										(!selectedFiles[0].isDir ||
											menu.folderSupport)) ||
									(menu.multipleFileSupport &&
										!selectedFiles.find((f) => f.isDir)) ||
									menu.folderSupport
							)
							.map((menu) => (
								<MenuItem
									key={menu.id}
									data={{ selectedFiles: selectedFiles }}
									onClick={() => menu.action(selectedFiles)}
								>
									{menu.label}
								</MenuItem>
							))}
					</ContextMenu>
				</FileContextMenu>
			)}
		</FileListWrapper>
	);
};
