import React, { useEffect, useState, useCallback } from "react";
import { UserHandler } from "@api/user-handler";
import styles from "./../styles.module.scss";
import classNames from "classnames";
import LeftNav from "../left-nav";
const { Option } = Select;
import { MdClose, MdContentCopy, MdDelete, MdEdit } from "react-icons/md";
import { Select, Table, Button, Modal } from 'antd';
import * as yup from 'yup';
import { errorAlert, infoAlert, successAlert } from "@components/toastify/notify-toast";
import { Formik, Form as FormikForm, Field, FormikHelpers } from 'formik';
import moment from "moment";
interface PlatformList {
  label: string;
  value: string;
}

type EditDetails = {
  name: string;
  username: string;
  platform: string;
  branch: string;
  url: string;
  accessToken: string;
  id: number
};

const GitSettingsPage: React.FC = () => {
  const [isLoading, setLoadingState] = useState(false);
  const [gitCardData, setGitCardData] = useState<any[]>([]);
  const [platformList, setPlatformList] = useState<PlatformList[]>([]);
  const [selectedPlatform, setSelectedPlatform] = useState<string | undefined>(undefined);
  const [editDetails, setEditDetails] = useState<EditDetails | null>(null);
  const [showSidebar, setShowSidebar] = useState(false);

  const gitFormSchema = yup.object({
    name: yup.string().required('Repository Name is required'),
    url: yup.string().required('This is a required field'),
    username: yup.string().required("This is a required field"),
    accessToken: yup.string().required("This is a required field"),
    branch: yup.string().required("This is a required field"),
  });

  const getPlatformList = useCallback(() => {
    setLoadingState(true);
    UserHandler.GetPlatformList((res) => {
      const modifiedRes = [
        {
          label: "Select a platform",
          value: ""
        },
        ...res
      ];
      setPlatformList(modifiedRes);
      setLoadingState(false);
    });
  }, []);

  const getGitReposList = useCallback(() => {
    setLoadingState(true);
    UserHandler.GetGitRepoDetails((res) => {
      if (selectedPlatform) {
        const filteredData = res.filter((repo: any) => repo.platform === selectedPlatform);
        setGitCardData(filteredData);
      } else {
        setGitCardData(res);
      }
      setLoadingState(false);
    });
  }, [selectedPlatform]);

  const onPlatformSelect = (value: string) => {
    setSelectedPlatform(value);
  };

  useEffect(() => {
    getGitReposList();
    getPlatformList();
  }, [getGitReposList, getPlatformList]);

  const onNewButtonClick = () => {
    setEditDetails(null);
    setShowSidebar(true);
  };

  
  const onDeleteRow = (e: any,userId: number) => {
    e.stopPropagation();
    Modal.confirm({
      title: 'Are you sure you want to delete this repository?',
      onOk: () => {
        UserHandler.DeleteGitRepoDetails(userId, (res) => {
          setLoadingState(false);
		  setEditDetails(null);
      setShowSidebar(false);
          getGitReposList();
        });
      },
    });
  };

  const onRowClick = (record: any) => {
    if (showSidebar) return;
    setEditDetails(record);
    setShowSidebar(true);
  };

  const onEditClick = (e: any, record: any) => {
    e.stopPropagation(); 
    setEditDetails(record);
    setShowSidebar(true);
};

const onSubmit = (
  values: any,
  { setSubmitting, setErrors }: FormikHelpers<EditDetails>
) => {
  const duplicateRepo = gitCardData.some(repo => repo.name === values.name);

  if (duplicateRepo) {
    setErrors({ name: "A repo with the same name is already present" });
    setSubmitting(false);
  } else {
    UserHandler.CreateGitRepo(
      values,
      () => {
        successAlert("Git Integration Settings saved successfully");
        getGitReposList();
        setShowSidebar(false);
      },
      (err) => {
        errorAlert(err.data);
        setSubmitting(false);
      }
    );
  }
};

const copyToClipboard = () => {
	const urlField = document.querySelector('input[name="url"]') as HTMLInputElement;
	if (urlField) {
	  urlField.select();
	  document.execCommand('copy');
	  infoAlert('Copied to Clipboard.');
	}
  };
  

	const columns = [
		{ title: 'Repository Name', dataIndex: 'name', key: 'name' },
		{ title: 'Repository Type', dataIndex: 'platform', key: 'platform', render: (text: string) => text === 'AZURE_DEVOPS' ? 'AZURE DEVOPS' : text },
		{ title: 'Last Updated', dataIndex: 'updated', key: 'updated', render: (text: string) => moment(text).format('MMMM D, h:mma') },
		{
		  title: 'Actions',
		  key: 'actions',
		  render: (text: any, record: any) => (
			<>
			  <Button type="link" style={{color:'#fcad01'}} onClick={(e) => onEditClick(e,record)}>Edit</Button>
        <Button type="link" style={{color:'#fcad01'}} onClick={(e) => onDeleteRow(e,record.id)}>Delete</Button>
			</>
		  ),
		},
	  ];

    const initialValues: EditDetails = {
      id: 0,
      name: '',
      platform: '',
      url: '',
      branch: '',
      username: '',
      accessToken: ''
    };

    const getRowClassName = (record: any, index: number) => {
      return 'custom-row'; // Return the class name for all rows
    };

  return (
    <section className={classNames(styles["admin__container"])}>
      <LeftNav selected_id="git" />
      <div className={styles["nifi_content"]}>
        <header style={{marginLeft:'20px'}}>
          <h1>
            <img src="/icons/git-integration.svg" alt="" />
            Git Management
          </h1>
        </header>
        <div className={styles["gitsearch_holder"]}>
          <Select
            showSearch
            placeholder="Select a Platform"
            filterOption={(input, option) =>
              (option?.toString().toLowerCase().includes(input.toLowerCase()) ?? false)
            }
            style={{ width: '230px', marginRight: '15px', borderStyle:'none' }}
            onChange={onPlatformSelect}
          >
            {platformList.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
          <Button
            className={classNames("btn-lg btn-yellow-transparent", styles["center"], styles["btn-lg"])}
            onClick={onNewButtonClick}
          >
            <img src="/icons/workflow/add.svg" alt="" />
            <span>New Git Repo</span>
          </Button>
        </div>
			  <div style={{ marginTop: '40px', padding: '15px' }}>
				  {gitCardData.length > 0 ? (
					  <Table
						  dataSource={gitCardData}
						  columns={columns}
						  pagination={{ pageSize: 5 }}
						  rowKey="id"
						  onRow={(record) => ({
							  onClick: () => onRowClick(record),
						  })}
              rowClassName={getRowClassName}
              // style={{ width: '80%' }}
               
					  />
				  ) : (
					  <span>No Data Available</span>
				  )}
			  </div>

      </div>
      {showSidebar && (
        <div className={styles["gitAdminsidebar"]}>
          <button
            onClick={() => setShowSidebar(false)}
            style={{ position: 'absolute', right: '10px', top: '10px' }}
          >
            <MdClose style={{ fontSize: '1.5rem' }} />
          </button>
          <Formik
            initialValues={editDetails || initialValues}
            validationSchema={gitFormSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ isValid, errors, touched }) => (
              <FormikForm className={styles["gitForm"]}>
                <div className={styles["gitSettingsForm"]} style={{ flexGrow: 1 }}>
                  <label className={styles["gitFormLabel"]}>Repository Name</label>
                  <Field name="name" placeholder="Name" className={styles["gitFormField"]}  disabled={!!editDetails?.id} required />
                  {errors.name && touched.name && !editDetails && (
          <span className={styles["errorText"]}>{errors.name}</span>
        )}

                  <label className={styles["gitFormLabel"]}>Repository Type</label>
                  <Field as="select" name="platform" placeholder="Select Platform" className={styles["gitFormField"]} disabled={!!editDetails?.id} required>
                    {platformList.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>

                  <label className={styles["gitFormLabel"]}>
                    Repository URL
                    <button type="button" onClick={copyToClipboard} className={styles["gitCopyUrlButton"]}>
                      <MdContentCopy />
                    </button>
                  </label>
                  <Field name="url" placeholder="URL" className={styles["gitFormField"]} disabled={!!editDetails?.id} required />

                  <label className={styles["gitFormLabel"]}>Branch</label>
                  <Field name="branch" placeholder="Branch" className={styles["gitFormField"]} disabled={!!editDetails?.id} required />

                  <label className={styles["gitFormLabel"]}>UserName</label>
                  <Field name="username" placeholder="Add Username" className={styles["gitFormField"]} required />

                  <label className={styles["gitFormLabel"]}>Password</label>
                  <Field name="accessToken" placeholder="Access Token" type="password" className={styles["gitFormField"]} required />
                </div>
                <div style={{ marginTop: 'auto', padding: '10px' }}>
                  <button
                    className="btn btn-lg btn-block btn-yellow"
                    type="submit"
                    disabled={!isValid}
                  >
                    {editDetails?.id ? "Confirm Changes" : "Save Settings"}
                  </button>
                </div>
              </FormikForm>
            )}
          </Formik>
        </div>
      )}

      {/* <div className="properties__container properties__container--minimized">
        <UserDetails hideNameOnMinimize={true} />
      </div> */}
    </section>
  );
};

export default GitSettingsPage;