import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { InPageSpinner } from '@components/spinners/in-page-spinner';
import styles from './../styles.module.scss';
import { StateData, TableData } from '../types';
import { TiWarningOutline } from 'react-icons/ti'
import { Refresh, Database } from '../icons';
import { AiFillDatabase } from "react-icons/ai";
import { DataSourceHandler } from '@api/data-source-handler';
import { TooltipTop } from '@components/tooltips';
import { infoAlert } from '@components/toastify/notify-toast';
import { useDataExplorerContext } from '../useDataExplorerContext';
import Form, { SelectField } from '@components/form';
import { _selectoptionType } from '@components/form/select-field';
import { ShowWhenTrue } from '@helpers/showwhentrue';
import { DataExplorerVisualization } from '../visualization/pages/data-explorer-visualization';
import TabsComponent from '@components/tabs';
import { Flex } from '@components/ui/Flex';
import { useGenAIContext } from '../gen-ai/useGenAIContext';


export const LeftContainer: React.FC<{
    isLoading: boolean,
    mainData: StateData,
}> = ({
    isLoading,
    mainData,
}) => {
        const { database, sendDataExplorerReducerCmd, theme } = useDataExplorerContext();
        const { setSqlQuery } = useGenAIContext();
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');
        const [tdata, setTableData] = useState<TableData[]>([]);
        const [_mainData, setData] = useState<StateData>();
        const location = useLocation();
        const isDrillingDataExplorer = location.pathname.includes('data-explorer-drilling-app');

        const getData = async (id: number) => {
            try {
                setLoading(true);
                setError('');
                const resp = await DataSourceHandler.GetDeepSqlDatabaseTableWithSchema(id)
                const results = resp.data.map((item: any) => {
                    return {
                        ...item,
                        tableName: item.name,
                        columnsMetaData: [],
                        foreignKeys: [],
                        primaryKeys: [],
                    }
                })
                setTableData(results);
                setLoading(false);
            } catch (e) {
                setLoading(false);
                setError('Internal error');
            }
        }

        useEffect(() => {
            setData(mainData)
        }, [mainData])

        useEffect(() => {
            if (isDrillingDataExplorer && !isEmpty(_mainData?.options)) {
                sendDataExplorerReducerCmd({
                    type: "SELECTED_DATABASE",
                    payload: {
                        id: mainData?.options?.[1]?.value,
                        name: mainData?.options?.[1]?.label,
                        databaseName: mainData?.options?.[1]?._extra.name,
                        dbType: mainData?.options?.[1]?.db_type,
                        tableId: mainData?.options?.[1]?._extra.id
                    }
                });
                getData(mainData?.options?.[1]?._extra.id)
            }

        }, [_mainData?.options])


        const onDataBaseRefresh = async (e: any) => {
            if (loading) return;
            e.preventDefault();
            e.stopPropagation();
            try {
                setLoading(true);
                const result = await DataSourceHandler.GetDeepSqlDatabaseTableWithSchema(database.tableId);
                infoAlert(result.data);
                setLoading(false);
            } catch (e) {
                setLoading(false);
            }
            return false;
        };

        const onChange = (key: string) => {
            // console.log(key);
        };


        return (
            <div className={styles["left_container"]} style={{ background: theme === "dark" ? '#030613' : '#F4F4F5', cursor:'context-menu'}}>
                <div>
                    <div className={styles["page_title"]} style={{ color: theme === "dark" ? '#fff' : '#000' }}>Data Explorer <div className={styles["beta"]}>BETA</div></div>
                </div>
                <div className={classNames(styles["m-1"], styles["overflow-x"])}>
                    {
                        isLoading ? (
                            <div className={classNames("align-middle", styles["m-5"])}>
                                <InPageSpinner />
                            </div>
                        ) : (
                            <div>
                                <Flex justifyContent={"space-between"}>
                                    <label className={styles['select-txt']} style={{ color: theme === "dark" ? '#fff' : '#000' }}>Select a Knowledge Graph </label>
                                    <Flex justifyContent={"end"}>
                                        <ShowWhenTrue show={database.id != -1}>
                                            <div className={styles["icon-container"]}>
                                                <TooltipTop title={"Refresh Database"}>
                                                    <div onClick={onDataBaseRefresh}>
                                                        <Refresh />
                                                    </div>
                                                </TooltipTop>

                                            </div>
                                        </ShowWhenTrue>
                                    </Flex>
                                </Flex>
                                <Form
                                    initialValues={{ db: isDrillingDataExplorer ? _mainData?.options?.[1]?.value : '' }}
                                    enableReinitialize
                                    onSubmit={() => {
                                        return;
                                    }}
                                    className="flex justify-between align-middle gap-1"
                                >
                                    <SelectField
                                        className={classNames(styles["mb-1"], styles["mt-1"], styles["w-full"], styles["selectDatabase"])}
                                        name="db"
                                        disabled={isDrillingDataExplorer}
                                        options={_mainData?.options || []}
                                        onOptionClick={(option) => {
                                            setSqlQuery('');
                                            sendDataExplorerReducerCmd({
                                                type: "SELECTED_DATABASE",
                                                payload: {
                                                    id: option.value,
                                                    name: option.label,
                                                    databaseName: option._extra.name,
                                                    dbType: option.db_type,
                                                    tableId: option._extra.id
                                                }
                                            });
                                            getData(option._extra.id)
                                        }}
                                        theme={theme}
                                    />
                                </Form>
                                {
                                    isEmpty(_mainData?.options) ? <div className='flex justify-center align-center'>
                                        <TiWarningOutline size='24' color="red" />
                                        &nbsp;
                                        <span className={styles['failed-txt']}>Failed to fetch knowledge graph data</span>

                                    </div> : null
                                }
                                {
                                    loading ? (
                                        <div className={classNames("flex align-middle justify", styles["m-5"])} style={{
                                            justifyContent: "center",
                                        }}>
                                            <InPageSpinner />
                                        </div>
                                    ) : null
                                }
                                <ShowWhenTrue show={!loading && database.id !== -1}>
                                    <TabsComponent
                                        onTabChange={onChange}
                                        items={[
                                            {
                                                key: 'graph',
                                                label: 'Graph',
                                                children: (

                                                    <div className={styles["tab-content-height"]}>
                                                        <ul className={styles['left-list']}>
                                                            <li >
                                                                {
                                                                    !loading && tdata && tdata.length === 0 && database.id !== -1 && error === '' ? <span>No Data</span> : null
                                                                }
                                                                {
                                                                    !loading && tdata && tdata.length ? (
                                                                        <ul style={{background: theme === "dark" ? '#030613' : '#fff', border: '1px solid black'}}>
                                                                            {
                                                                                tdata.map((_tdata: TableData, index: number) => {
                                                                                    return <TableList key={_tdata.tableName + index} tableData={_tdata} dbData={database} />
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    ) : null
                                                                }

                                                            </li>
                                                        </ul>
                                                    </div>
                                                )
                                            }, {
                                                key: 'visualizations',
                                                label: 'Visualizations',
                                                children: (
                                                    <div className={styles["tab-content-height"]} style={{overflowY:'hidden'}}>
                                                        <DataExplorerVisualization />
                                                    </div>
                                                )
                                            }
                                        ]}
                                        theme={theme}
                                    />

                                </ShowWhenTrue>

                            </div>
                        )
                    }
                </div>

            </div>
        );
    };

const TableList: React.FC<{ tableData: TableData, dbData: any}> = ({ tableData, dbData }) => {
    const { setDBTable, db_table, theme } = useDataExplorerContext();
    return (
        <li key={tableData.tableName + dbData.id} >
            <div className={classNames({
                [styles['selected']]: db_table === tableData.tableName
            }, { padding: 4 })} onClick={() => {

                setDBTable(tableData.tableName)
            }}
            style={{ backgroundColor: theme === "dark" ? '#000' : '#fff' }}>
                <div className={styles['icon-holder']}><AiFillDatabase color='#898C9A'/></div>
                <TooltipTop title={tableData.tableName} ><span className={classNames(styles['grow-1'], styles['ml-1'])} style={{ color: theme === "dark" ? '#fff' : '#000' }}>{tableData.tableName}</span></TooltipTop>

            </div>
        </li>
    );
};

