import React, { useEffect, useState } from 'react'
import styles from './../styles.module.scss';
import { PiRobotBold } from 'react-icons/pi'
import { GenAITableOutput, MESSAGE_TYPE } from '../types';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { RiUser6Fill } from 'react-icons/ri'
import TimeAgo from 'react-time-ago'
import { ThumbDown, ThumbUp } from '@pages/data-explorer/icons';
import { useDataExplorerContext } from '@pages/data-explorer-new/useDataExplorerContext';
import { DataSourceHandler } from '@api/data-source-handler';
import { useSelector } from 'react-redux';
import { RootState } from '@store/types';
import { useGenAIContext } from '../useGenAIContext';
import RowRenderTable from './table';

export type RowRenderProps = {
    text: string | string[],
    icon?: React.ReactElement,
    direction: MESSAGE_TYPE,
    date: Date,
    isFeedbackSubmitted: boolean,
    showFeedback: any
    noFeedback: boolean
    query_uuid: string,
    table?: React.ReactElement | null
    tabelData?: GenAITableOutput | null,
}

const RowRender: React.FC<RowRenderProps> = ({
    text,
    icon = <PiRobotBold />,
    direction,
    date,
    isFeedbackSubmitted,
    showFeedback,
    noFeedback,
    query_uuid,
    table,
    tabelData
}) => {
    const upVote = async () => {
        showFeedback(false, true, query_uuid)
    }
    const downVote = () => {
        showFeedback(true, false, query_uuid)
    }

    const { database, theme } = useDataExplorerContext();
    const { activeUserInfo } = useSelector((store: RootState) => store.AccountReducer);
    const [feedback, setFeedback] = useState<boolean | null>(null);

    useEffect(() => {
        if (database?.id !== -1) {
            try {
                DataSourceHandler.ShowHideFeedBack(activeUserInfo.username, (res) => {
                    const nodes = res.config.feedback
                    setFeedback(nodes);
                })
            } catch (error) {
                console.error('Error fetching user config:', error);
            }
        }
    }, [database?.id]);

    let darKLightStyle={backgroundColor:'', color:''}
    if(theme =='dark' && direction=='ai'){
        darKLightStyle={backgroundColor:'#030613', color:'#fff'};
    }else if(theme=='dark' && direction == 'human'){
        darKLightStyle={backgroundColor:'#FBC441', color:'black'};
    }else if(theme != 'dark' && direction=='ai'){
        darKLightStyle={backgroundColor:'#fff', color:'#000'};
    }else {
        darKLightStyle={backgroundColor:'#FBC441', color:'#000'};
    }


    return (
        <div>
            <div className={classNames(styles['rowRender'], {
                [styles['user']]: direction === "human"
            })}>
                <div className={classNames(styles['user_display'], styles['user_display_' + direction])}>
                    <span className={styles['avatar']}>
                        {
                            direction === "human" ? <RiUser6Fill /> : icon
                        }
                    </span>
                    <span className={styles['username']} style={{ color: theme === "dark" ? '#fff' : '#000' }}>
                        {
                            direction === "human" ? "Me" : "GenAI Assistant"
                        }
                    </span>
                    <span className={styles['time']}>
                        <TimeAgo date={date}></TimeAgo>
                    </span>
                </div>
                <div className={classNames(styles['text_holder'], styles['text_holder_' + direction], {
                    [styles['isArray']]: Array.isArray(text)
                })}>
                    {
                        Array.isArray(text) ? text.map((t, index: number) => {
                            return (
                                <>
                                    <div key={'p' + index} className={classNames(styles['text'], styles['text_' + direction])}>
                                        {t}
                                    </div>
                                </>
                            )
                        }) : (
                            <>
                                <div className={classNames(styles['text'], styles['text_' + direction])} style={darKLightStyle}>
                                    {text}
                                    {!isEmpty(tabelData) ?<RowRenderTable tabelData={tabelData} />:table}
                                </div>
                                {
                                    !noFeedback && direction === "ai" && (
                                        <div style={text.length > 90 ? { marginLeft: '2.7rem', marginTop: '0.5rem' } : {}}>
                                            {feedback ? <>
                                                {
                                                    !isFeedbackSubmitted ? (
                                                        <div className={styles['round_btn_holder']}>
                                                            <span className={styles['round_btn']} onClick={upVote}>
                                                                <ThumbUp />
                                                            </span>
                                                            <span className={styles['round_btn']} onClick={downVote}>
                                                                <ThumbDown />
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <p className={classNames(styles['feedback'], 'ml-1')}>Thank you for your feedback!</p>
                                                    )
                                                }
                                            </> : <></>}
                                        </div>
                                    )
                                }
                            </>
                        )
                    }
                    {
                        Array.isArray(text) && !noFeedback && direction === "ai" && (
                            <div style={text.length > 90 ? { marginLeft: '2.7rem', marginTop: '0.5rem' } : {}}>
                                {
                                    !isFeedbackSubmitted ? (
                                        <div className={styles['round_btn_holder']}>
                                            <span className={styles['round_btn']} onClick={upVote}>
                                                <ThumbUp />
                                            </span>
                                            <span className={styles['round_btn']} onClick={downVote}>
                                                <ThumbDown />
                                            </span>
                                        </div>
                                    ) : (
                                        <p className={classNames(styles['feedback'], 'ml-1')}>Thank you for your feedback!</p>
                                    )
                                }
                            </div>
                        )
                    }
                </div>

            </div>
        </div>
    )
}

export default RowRender