import { Modal } from '../../../components/modals';
import { object, string, InferType, number } from 'yup';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Form, { InputField, SelectField } from '../../../components/form';
import { WorkflowHandler, CreateWorkflow, CreateWorkflowResponse, DirectoryInfo, WorkflowSearchQuery } from '../../../api/workflow-handler';
import { getWorkflowDirectories, retrieveWorkflows, setWorkflowTabSelection, toggleModal } from '../../../store/workflow';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/types';
import { openANewWorkflowEditorTab, WorkflowCanvasTabInfo } from '../../../store/canvas';
import { capitalize, isEmpty } from 'lodash';
import { _selectoptionType } from '../../../components/form/select-field';
import { NewExperimentAddIcon } from '../../../components/formcreators/icons';
import classNames from 'classnames';
import { ShowWhenTrue } from '../../../helpers';
import { InnerWorkflowTabs } from '../enums';
import { InPageSpinner } from '../../../components/spinners/in-page-spinner';
import { errorHandlerFor400 } from '../../../api/services/errorhandler';
import { AxiosResponse } from 'axios';
import { useDebounce } from 'rooks';
import { getActiveWorkflowType } from '../../../utils';
import { noBlankSpaceInputFieldRegex, noEmptySpaceRegex, noSpecialCharactersRegex } from '@constants/enums';


const NewWorkflowSchema = (workflowType: string) => object().shape({
    projectName: string()
        .required(`${workflowType} name is required.`)
        .matches(noSpecialCharactersRegex,
            'No Special Characters allowed'
        )
        .matches(noBlankSpaceInputFieldRegex,
            'Blank spaces not allowed'
        ),
    directoryId: number()
});

const NewWorkflowNewDirectorySchema = (workflowType: string) => object().shape({
    projectName: string()
        .required(`${workflowType} name is required.`)
        .matches(noSpecialCharactersRegex,
            'No Special Characters allowed'
        )
        .matches(noBlankSpaceInputFieldRegex,
            'Blank spaces not allowed'
        ),
    newDirectory: string()
        .required('Directory name is required.')
        .matches(noSpecialCharactersRegex,
            'No Special Characters allowed'
        )
        .matches(noEmptySpaceRegex,
            'Blank spaces not allowed'
        )
        .test('Unique name', 'Directory name exists', function(this: any, value: string) {
            return !this.parent.directoryOptions.find((t: any) => t.label === value);
        }), 
});

type NewWorkflowSchema = InferType<ReturnType<typeof NewWorkflowSchema>>
type NewWorkflowNewDirectorySchema = InferType<ReturnType<typeof NewWorkflowNewDirectorySchema>>


interface NewWorkflowModalProps {
    handleCreateNewWorkflowSuccess?: (arg0: CreateWorkflowResponse) => void;
    retrieveWorkflowsOnSuccess: (arg0: Partial<WorkflowSearchQuery>) => any;
}

export const NewWorkflowModal: React.FC<NewWorkflowModalProps> =  ({ retrieveWorkflowsOnSuccess }) => {
    const showModal = useSelector((store: RootState) => store.WorkflowReducer.showModal.newWorkflow);
    const { workflowTabSelection: { selectedDirectory, activeInnerTab } } = useSelector((store: RootState) => store.WorkflowReducer);
    // const userDirectories = useSelector((store: RootState) => store.AccountReducer.userDirectories);
    const userId = useSelector((store: RootState) => store.AccountReducer.activeUserInfo.id);
    const dispatch = useDispatch();
    const [directoryOptions, setDirectoriesInfo] = useState<_selectoptionType[]>([]);
    const directoryOptionsFieldRef = useRef<SelectField>(null);
    const [showNewDirectoryField, toggleNewDirectoryField] = useState(false);
    const { activeExecutionEnv } = useSelector((store: RootState) => store.CommonReducer);
    const [showSpinner, toggleSpinner] = useState(false);
    const activeEnvDirectoriesInfo = useSelector((store: RootState) => store.AccountReducer.userDirectories[store.CommonReducer.activeExecutionEnv]);
    

    function toggleClose() {
        dispatch(toggleModal('newWorkflow', false));
    }
    
    function handleCreateNewWorkflowSuccess(response: CreateWorkflowResponse, newDirectoryInfo?: null | DirectoryInfo) {
        const { id, projectName, version, readOnly, executable, zeppelinNotebookId, directoryId, env, created, updated } = response.data;
        const newWorkflowDetails: WorkflowCanvasTabInfo = { id, name: projectName, details: '', version, saved: false, isStarred: false, notes: '', createdBy: userId, isReadOnly: readOnly, isExecutable: executable, hasVariables: false, activeComponentInfo: null, componentCounter: 0, zeppelinNotebookId, showCodeEditor: false, showHelpBulbAnimation: false, helpDocRef: null, workflowPositionInList: 1, directoryId, env, scheduleInfoForDag: null, activeClusterInfoForZeppelin: null, zepplinData: [], isPreviewSessionCreationInProgress: false, created, updated, config: [] };
        if(activeInnerTab === InnerWorkflowTabs.WORKFLOWS && selectedDirectory?.id === directoryId) {
            // user created a workflow in the current directory
            retrieveWorkflowsOnSuccess({}); 
        }
        if(newDirectoryInfo) {
            dispatch(setWorkflowTabSelection('selectedDirectory', newDirectoryInfo));
            dispatch(retrieveWorkflows({ directory_id: newDirectoryInfo.id }));
            dispatch(getWorkflowDirectories());
        }
        dispatch(openANewWorkflowEditorTab(newWorkflowDetails));
    }

    function handleCreateWorkflowSuccessResponse(newDirectoryName: null | string, response: CreateWorkflowResponse, ) {
        const newDirectoryInfo: null | DirectoryInfo = newDirectoryName ? { 
            id: response.data.directoryId, userId, readOnly: false, name: newDirectoryName, workflowCount: 1 }: null; 
        handleCreateNewWorkflowSuccess(response, newDirectoryInfo);
        toggleClose();
        toggleSpinner(false);
    }

    const handleCreateWorkflowError = (err: AxiosResponse) => {
        toggleSpinner(false);
        errorHandlerFor400(err);
    };

    const handleCreateNewWorkflow = useDebounce(function handleCreateNewWorkflow(this: any, values: NewWorkflowSchema | NewWorkflowNewDirectorySchema) {
        if(!showSpinner) {
            toggleSpinner(true);
            const data: CreateWorkflow = { 
                projectName: values.projectName.trim(), 
                payload: '', 
                jobtype: 'DT', 
                status: true, 
                details: '', 
                env: activeExecutionEnv,
                config: '[]'
            };
            if(showNewDirectoryField) {
                data.newDirectory = (values as NewWorkflowNewDirectorySchema).newDirectory;
            } else {
                data.directoryId = (values as NewWorkflowSchema).directoryId;
            }
            WorkflowHandler.CreateWorkflow(data, handleCreateWorkflowSuccessResponse.bind(this, showNewDirectoryField ? (values as NewWorkflowNewDirectorySchema).newDirectory: null), handleCreateWorkflowError);
        }
    }, 250);

    const handleSetDirectoriesInfo = (directories: DirectoryInfo[]) => {
        let defaultDirectoryId = activeEnvDirectoriesInfo?.defaultDirectoryId || 0;
        const directoryOptions = directories.map(proj => {
            // if(proj.name.toLowerCase() === 'default directory')
            //     defaultDirectoryId = proj.id;
            return({ label: proj.name, value: proj.id  });
        });
        if(!defaultDirectoryId && !isEmpty(directoryOptions)) {
            defaultDirectoryId = directoryOptions[0].value;
        }
        setDirectoriesInfo(directoryOptions);
    };


    useEffect(() => {
        if(showModal)
            toggleNewDirectoryField(false);
        handleSetDirectoriesInfo(activeEnvDirectoriesInfo['directories']);
        // handleSetDirectoriesInfo(userDirectories[activeExecutionEnv]);
    }, [showModal, activeEnvDirectoriesInfo]);

    const newDirectoryInitialValue = useMemo(() => {
        return '';
    }, [showModal, showNewDirectoryField]);
    
    const activeWorkflowType = useMemo(() => capitalize(getActiveWorkflowType(activeExecutionEnv)), [activeExecutionEnv]);

    const formSchema = useMemo(() => (
        showNewDirectoryField ? NewWorkflowNewDirectorySchema(activeWorkflowType): NewWorkflowSchema(activeWorkflowType)
    ), [activeWorkflowType, showNewDirectoryField]);
    const heading=activeWorkflowType=='Local'?'Workflow':activeWorkflowType;

    return(
        <Modal
            isOpen={showModal}
            toggleClose={toggleClose}
            className="new__workflow__modal"
            title={`Create a ${heading}`}
        >
            <Form
                validationSchema={formSchema}
                initialValues={{projectName: '', directoryId: activeEnvDirectoriesInfo.defaultDirectoryId, newDirectory: newDirectoryInitialValue,directoryOptions }}
                onSubmit={handleCreateNewWorkflow}
                enableReinitialize
            >
                {({ _formikprops: { errors, touched } }) => (
                    <>
                        <InputField
                            name="projectName"
                            label={`Name your ${heading}`}
                            autoComplete="off"
                            autoFocus
                        />
                        <SelectField 
                            name="directoryId"
                            label="Add to Directory"
                            options={directoryOptions}
                            ref={directoryOptionsFieldRef}
                            renderItemAtOptionsBottom={() => (
                                <button
                                    id="btn_new_experiment"
                                    onClick={() => {
                                        toggleNewDirectoryField(true);
                                        directoryOptionsFieldRef.current?.closeDropdown();
                                    }}
                                    type="button"
                                >
                                    <NewExperimentAddIcon /> New Directory
                                </button>
                            )}
                            className={classNames({'hide': showNewDirectoryField})}
                        />
                        <ShowWhenTrue show={showNewDirectoryField}>
                            <InputField 
                                name="newDirectory"
                                label="Add to Directory"
                                className="newDirectory__inputField"
                            >
                                <ShowWhenTrue show={!(errors.newDirectory && touched.newDirectory)}>
                                    <span
                                        className="nameProject__title"
                                    >
                                        Name your new directory
                                    </span>
                                </ShowWhenTrue>
                                <img
                                    src="/icons/treeview/search-cross.svg"
                                    alt=""
                                    className="search_reset"
                                    onClick={() => toggleNewDirectoryField(false)}
                                />
                            </InputField>
                        </ShowWhenTrue>
                        <div className="modalBtns__box">
                            <button 
                                className="btn-md btn-yellow"
                                type="submit"
                                aria-label="confirm-create-workflow"
                            >
                                {showSpinner ?
                                    <InPageSpinner size="XSmall" color="black" />
                                    :
                                    <span>Create</span>
                                }
                            </button>
                            <button 
                                className="btn-md btn-cancel"
                                type="button"
                                onClick={toggleClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </>
                )}
                
                
            </Form>

        </Modal>
    );
};
