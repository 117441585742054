import { useEffect, useState } from "react";
import { uuid } from "uuidv4";
import { isEmpty } from "lodash";
import { DataSourceHandler } from "@api/data-source-handler";
import { errorHandlerFor400 } from "@api/services/errorhandler";
import {
	Plot,
	PlotConfig,
	VisualizationHandler,
} from "@api/visualization-handler";
import { useGenAIContext } from "@pages/data-explorer-new/gen-ai/useGenAIContext";
import { useDataExplorerContext } from "@pages/data-explorer-new/useDataExplorerContext";
import { graphTypes } from "@pages/workflow-analytics-page/enums";
import { DropResult } from "react-beautiful-dnd";
import { GenAITableOutput } from "@pages/data-explorer/gen-ai/types";
import { useSelector } from "react-redux";
import { RootState } from "@store/types";

const _initialValuesPlotForm = {
	plot_type: graphTypes[3].value,
	aggregate_type: "",
	additional_params: {},
	groupby_cols: [],
	x: "",
	y: [],
	plotData: null,
};

export const useVisualization = () => {
	const [showVisualizationPopup, setShowVisualizationPopup] = useState(false);
	const [queryOutput, setQueryOutput] = useState<GenAITableOutput | null>(
		null
	);
	const [columnsList, setColumnsList] = useState<string[]>([]);
	const [plotData, setPlotData] = useState<Plot[]>([]);
	const [selectedPlotItem, setSelectedPlotItem] = useState<Plot | null>(null);
	const [initialValuesPlotForm, setInitialValuesPlotForm] = useState(
		_initialValuesPlotForm
	);
	const { database } = useDataExplorerContext();
	const { sqlQuery, sessionId,resultMode } = useGenAIContext();
	const { activeUserInfo: { username: loggedUserName } } = useSelector((store: RootState) => store.AccountReducer);

	const openPlotPopUp = () => {
		setSelectedPlotItem(null);
		setShowVisualizationPopup(true);
	};

	const handleSettings = (item: Plot) => {
		setSelectedPlotItem(item);
		setInitialValuesPlotForm({
			...initialValuesPlotForm,
			plot_type: item.config.plot_type,
			aggregate_type: item.config.aggregate_type,
		});
		setShowVisualizationPopup(true);
	};

	const handleDeletePlot = (plotItem: Plot) => {
		const updatedPlotData = plotData.filter(
			(item) => item.config.id !== plotItem.config.id
		);
		setPlotData(updatedPlotData);
	};

	const handleClose = () => {
		setShowVisualizationPopup(false);
	};

	const fetchSessionQueryResults = async () => {
		const resp = resultMode ==="langgraph"?await DataSourceHandler.DeepSQLChatLanggraph(
			database.id,
			sessionId,
			{
				content: sqlQuery,
				node: "get_data",
			},
			loggedUserName
		) : await DataSourceHandler.DeepSQLChat(
			database.id,
			sessionId,
			{
				human_message: {
					content: sqlQuery,
					next_action: "retrieve_data",
				},
			},
			loggedUserName
		);
		const _queryOutput: any = resp.data.ai_message.output?.output_value;
		setColumnsList(Object.keys(_queryOutput) || []);
		setQueryOutput(_queryOutput);
	};

	useEffect(() => {
		if (!isEmpty(sqlQuery)) {
			fetchSessionQueryResults();
		}
	}, [sqlQuery, sessionId]);

	const handlePlotSubmit = (config: Plot) => {
		const payload = {
			...config,
			plotData: queryOutput,
		};
		if (config.config.plot_type === "correlation") {
			payload.config.y = columnsList;
			payload.config.x = null;
		}
		VisualizationHandler.SubmitPlot(
			payload,
			(response: PlotConfig) => {
				if (selectedPlotItem && !isEmpty(selectedPlotItem.config.id)) {
					const updatedConfig = {
						config: { ...response, id: selectedPlotItem.config.id },
					};
					const newPlotData = plotData.map((item) => {
						if (item.config.id === selectedPlotItem.config.id) {
							return updatedConfig;
						} else {
							return item;
						}
					});
					setPlotData(newPlotData);
				} else {
					setPlotData([
						...plotData,
						{ config: { ...response, id: uuid() } },
					]);
				}
				setShowVisualizationPopup(false);
			},
			(error: any) => {
				errorHandlerFor400(error);
			}
		);
	};

	const handleOnDragEnd = (result: DropResult) => {
		if (!result.destination) return;
		const items = Array.from(plotData);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		setPlotData(items);
	};

	return {
		showVisualizationPopup: showVisualizationPopup,
		plotData,
		queryOutput,
		openPlotPopUp,
		handleSettings,
		handleClose,
		columnsList,
		handlePlotSubmit,
		selectedPlotItem,
		initialValuesPlotForm,
		handleDeletePlot,
		handleOnDragEnd,
	};
};
